import React from 'react';
import { Button } from 'react-bootstrap';
import { strings } from '../../shared';

export const ShareTextViaEmailButton = () => {
  const shareByEmail = () => {
    window.open(
      `mailto:?body=${encodeURIComponent(strings.share.share_facebook)}`,
      '_blank'
    );
  };
  return (
    <Button
      className="buttonEmail mainLayout"
      variant="outline-secondary"
      target="_blank"
      rel="noopener noreferrer"
      onClick={shareByEmail}
    >
      {strings.share.share_via_email}
    </Button>
  );
};

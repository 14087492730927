import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useResultsState } from '../../../context/ResultsContext.js';
import { getAdvertisersByParty, strings } from '../../../shared';
import { ChartPartyAdFrequency } from '../charts/ChartPartyAdFrequency';
import {
  Section,
  SectionBody,
  SectionContent,
  SectionFooter,
  SectionTitle,
} from './Section';

const percentage = (value, total) => (value / total) * 100;

export function SectionPartyTargetingYou() {
  const { entities_summary, adverts } = useResultsState();
  const [userParties, setUserParties] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const shouldGetAdvertisersByParty =
        !adverts.is_loading &&
        adverts.records &&
        adverts.records.length !== 0 &&
        entities_summary.records.length !== 0;

      if (shouldGetAdvertisersByParty) {
        const res = getAdvertisersByParty(
          adverts.records,
          entities_summary.records
        );
        setUserParties(res);
      }
    };

    fetch();
  }, [adverts, entities_summary]);

  const totalUserViews = userParties.reduce(
    (acc, curr) => acc + parseInt(curr.advertiserCount, 10),
    0
  );

  const availablePartiesMap = _.keyBy(userParties, 'advertiserName');

  const userPartiesData = userParties.map((p) => ({
    advertiserName: p.advertiserName,
    value: percentage(p.advertiserCount, totalUserViews).toFixed(0),
    color: p.color,
    entityId: availablePartiesMap[p.advertiserName]['entityId'],
  }));

  const data = _.orderBy(
    _.uniqBy([...userPartiesData, ...userParties], 'advertiserName'),
    (o) => parseInt(o.value),
    'desc'
  ).map(({ advertiserName: party, value: percentage, ...restOfValues }) => ({
    party,
    percentage,
    ...restOfValues,
  }));

  if (userParties.length === 0 || adverts.records.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>{strings.which_party_is_targeting_you.title}</SectionTitle>
      <SectionBody>
        <SectionContent>
          <div style={{ width: '100%', height: '400px' }}>
            <ChartPartyAdFrequency data={data} />
          </div>
        </SectionContent>
        <SectionFooter>
          <div className="pb-3">
            {strings.results.how_did_we_calc3}
            {` | `}
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://whotargets.me/en/defining-political-ads/"
            >
              {strings.results.how_did_we_calc2}
            </a>
          </div>
        </SectionFooter>
      </SectionBody>
    </Section>
  );
}

import { locales, setLanguageFromStorage, strings } from './localization';

/**
 * Discover any language files in the locale folder
 * Add the new language json to the locales object
 */
export async function getLocaleData() {
  const localeFiles = require.context('../../locale', false, /\.json$/);
  return Promise.all(
    localeFiles.keys().map(async (fileName) => {
      // fileName is './en.json'
      let filenameClean = fileName.replace('./', '');
      let lang = filenameClean.replace('.json', '');

      let content = await import(`../../locale/${filenameClean}`).then(
        (module) => module.default
      );

      locales[lang] = content;

      strings.setContent(locales);
    })
  ).then(() => {
    // reset after loading locale files
    setLanguageFromStorage();
  });
}

import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import PageConsent from '../PageConsent';
import PageLanguage from '../PageLanguage';
import PageShare from '../PageShare';
import {
  persistedRegistrationState,
  registrationMachine,
  registrationModel,
} from './stateMachine';
import { wtmdata } from '../../shared';
import PageAboutMe from '../PageAboutMe';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import './styles.css';
import { PageResearchStudies } from '../PageResearchStudies/PageResearchStudies';
import { fetchCountries } from '../../api';
import {
  useResultsDispatch,
  useResultsState,
} from '../../context/ResultsContext';

const generalToken = localStorage.getItem('general_token');
if (!generalToken) {
  localStorage.removeItem('wtmdata');
}

export const PageRegistration = () => {
  const history = useHistory();
  const { page_path } = useParams();
  const [locationKeys, setLocationKeys] = useState([]);
  const { countries } = useResultsState();
  const dispatch = useResultsDispatch();
  const [state, send, service] = useMachine(registrationMachine, {
    state: persistedRegistrationState,
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (!wtmdata) {
        localStorage.setItem(
          'registration_machine_state',
          JSON.stringify(state)
        );
      }
    });

    if (wtmdata) {
      localStorage.removeItem('registration_machine_state');
    }

    return () => subscription.unsubscribe();
  }, [service]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          handleNextStateTransition(state?.event?.values);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          handlePreviousStateTransition();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys]);

  useEffect(() => {
    // Fetching countries in this component to reduce the time
    // it takes to make the request + populate the list
    const fetch = async () => {
      if (countries.is_empty && countries.records.length === 0) {
        const countries = await fetchCountries({ query: { $limit: -1 } });
        dispatch({ type: 'FETCH_COUNTRIES', payload: countries });
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const handleNextStateTransition = (values) => {
    send(registrationModel.events.NEXT(values, state));
  };

  const handlePreviousStateTransition = () => {
    send(registrationModel.events.PREVIOUS());
  };

  if (localStorage.getItem('wtmdata')) {
    history.push('/');
  }

  switch (page_path) {
    case 'language':
      return (
        <PageLanguage
          state={state}
          transitionToNextState={handleNextStateTransition}
          transitionToPreviousState={handlePreviousStateTransition}
        />
      );
    case 'consent':
      return (
        <PageConsent
          state={state}
          transitionToNextState={handleNextStateTransition}
          transitionToPreviousState={handlePreviousStateTransition}
        />
      );
    case 'about-me':
      return (
        <PageAboutMe
          state={state}
          transitionToNextState={handleNextStateTransition}
          transitionToPreviousState={handlePreviousStateTransition}
        />
      );
    case 'research-studies':
      return (
        <PageResearchStudies
          state={state}
          transitionToNextState={handleNextStateTransition}
        />
      );
    case 'share':
      return <PageShare userCountry={state?.event?.values?.country} />;
    default:
      return null;
  }
};

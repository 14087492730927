export const FACEBOOK_AGE_OFFSET = 12;

export const USER_LOCAL_STORAGE_KEY = 'wtm_user';

export const USER_AUTH_LOCAL_STORAGE_KEY = 'general_token';

export const AVAILABLE_PLATFORMS = [
  'facebook',
  'youtube',
  'twitter',
  'instagram',
];

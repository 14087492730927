export const config = Object.freeze({
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',

  NODE_ENV: process.env.NODE_ENV || '',

  PUBLIC_URL: process.env.PUBLIC_URL || '',

  REACT_APP_DATA_API_BASE:
    process.env.REACT_APP_DATA_API_BASE || 'http://localhost:3003',

  REACT_APP_CF_IMAGE_HOST: process.env.REACT_APP_CF_IMAGE_HOST || '',

  REACT_APP_MAILCHIMP_URL: process.env.REACT_APP_MAILCHIMP_URL,
  REACT_APP_MAILCHIMP_U: process.env.REACT_APP_MAILCHIMP_U,
  REACT_APP_MAILCHIMP_ID: process.env.REACT_APP_MAILCHIMP_ID,

  TERMS_AND_CONDITIONS_DATE: process.env.TERMS_AND_CONDITIONS_DATE || '',
  APP_VERSION: process.env.APP_VERSION || '',
});

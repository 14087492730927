import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  NavigationButtons,
  FormikError,
  PageRegistrationContainer,
  strings,
} from '../../shared';
import { getUser } from '../../shared';
import clsx from 'clsx';
import { config } from '../../config';

const TermsLink = () => (
  <a
    href="https://whotargets.me/en/terms/"
    target="_blank"
    rel="noopener noreferrer"
  >
    {strings.register.terms2}
  </a>
);

const PrivacyLink = () => (
  <a
    href="https://whotargets.me/en/privacy-policy/"
    target="_blank"
    rel="noopener noreferrer"
  >
    {strings.register.terms4}
  </a>
);

export const PageConsent = ({
  state,
  transitionToNextState,
  transitionToPreviousState,
}) => {
  const location = useLocation();
  const history = useHistory();
  const user = getUser();

  const shouldAgreedToLatestTerms =
    user.isLoggedIn && !user.hasAgreedToLatestTermsAndConditions;

  const shouldConsentForAllPlatforms =
    user.isLoggedIn && !user.hasConsentForAllPlatforms;

  const showAlertToUser =
    user.isLoggedIn &&
    (shouldAgreedToLatestTerms || shouldConsentForAllPlatforms);

  // terms and conditions message trumps platform permissions message, is only visible if showAlertToUser is true
  const alertMessage = shouldAgreedToLatestTerms
    ? strings.register.terms_changed_notification
    : strings.register.platform_changed_notification;

  // Default values for platform permissions
  let allowFacebookAdvertisementCollect = true;
  let allowTwitterAdvertisementCollect = true;
  let allowYouTubeAdvertisementCollect = true;
  let allowInstagramAdvertisementCollect = true;

  if (user.isLoggedIn) {
    const { facebook, instagram, twitter, youtube } = user.platforms;

    allowFacebookAdvertisementCollect =
      facebook !== undefined ? facebook : true; // default to true if not set as this was default of the initial extension
    allowInstagramAdvertisementCollect = !!instagram;
    allowTwitterAdvertisementCollect = !!twitter;
    allowYouTubeAdvertisementCollect = !!youtube;
  }

  const formik = useFormik({
    initialValues: {
      consent1: false,
      consent2: false,
      consent3: false,
      allowFacebookAdvertisementCollect,
      allowTwitterAdvertisementCollect,
      allowYouTubeAdvertisementCollect,
      allowInstagramAdvertisementCollect,
    },
    validationSchema: Yup.object({
      allowFacebookAdvertisementCollect: Yup.bool(),
      allowTwitterAdvertisementCollect: Yup.bool(),
      allowYouTubeAdvertisementCollect: Yup.bool(),
      allowInstagramAdvertisementCollect: Yup.bool(),
      consent1: Yup.bool().oneOf([true], strings.errors.required),
      consent2: Yup.bool().oneOf([true], strings.errors.required),
      consent3: Yup.bool().oneOf([true], strings.errors.required),
    }),
    onSubmit: async (values) => {
      const {
        terms_consent1,
        terms_consent2,
        terms_consent3,
      } = strings.register;
      values = {
        ...values,
        termsConsent: terms_consent1 + terms_consent2 + terms_consent3,
      };
      updateUserConsent(values);
      transitionToNextState(values);
    },
  });

  // Redirect users to /registration/language if an account is not created
  if (location.pathname === '/language' && !localStorage.getItem('wtmdata')) {
    history.push('/registration/language');
  }

  const updateUserConsent = (values) => {
    const {
      allowFacebookAdvertisementCollect: facebook,
      allowInstagramAdvertisementCollect: instagram,
      allowTwitterAdvertisementCollect: twitter,
      allowYouTubeAdvertisementCollect: youtube,
    } = values;

    const consentedPlatforms = {
      facebook,
      instagram,
      twitter,
      youtube,
    };

    user.updateConsent(consentedPlatforms);
  };

  return (
    <PageRegistrationContainer>
      <form onSubmit={formik.handleSubmit}>
        <section>
          {showAlertToUser && (
            <div
              className="alert alert-warning alert-pop p-4 d-flex align-items-center white-space-pre-line"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-bell mr-3"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
              </svg>{' '}
              {alertMessage}
            </div>
          )}
          <h5 className="font-weight-bold mt-4 mb-3">
            {strings.register.terms5}
          </h5>
          <ul>
            <li style={{ listStyle: 'disc' }}>{strings.register.terms6}</li>
            <li style={{ listStyle: 'disc' }}>{strings.register.terms7}</li>
          </ul>

          <div
            className={clsx('border border-gray p-3 rounded-lg', {
              'border-warning border-warning-pop': shouldConsentForAllPlatforms,
            })}
          >
            <p className="mx-2">
              {strings.register.platform_we_collect_advertisements_on_title}
            </p>
            <dl className="d-flex flex-wrap m-0 col-10">
              <dt className="d-flex align-items-center col-6 col-md-3 my-2">
                <label className="d-flex">
                  <input
                    type="checkbox"
                    name="allowFacebookAdvertisementCollect"
                    checked={formik.values.allowFacebookAdvertisementCollect}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="ml-2">
                    {strings.register.platform_titles.facebook}
                  </span>
                </label>
              </dt>

              <dt className="d-flex align-items-center col-6 col-md-3 my-2">
                <label className="d-flex">
                  <input
                    type="checkbox"
                    name="allowInstagramAdvertisementCollect"
                    checked={formik.values.allowInstagramAdvertisementCollect}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="ml-2">
                    {strings.register.platform_titles.instagram}
                  </span>
                </label>
              </dt>
              <dt className="d-flex align-items-center col-6 col-md-3 my-2">
                <label className="d-flex">
                  <input
                    type="checkbox"
                    name="allowYouTubeAdvertisementCollect"
                    checked={formik.values.allowYouTubeAdvertisementCollect}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="ml-2">
                    {strings.register.platform_titles.youtube}
                  </span>
                </label>
              </dt>
              <dt className="d-flex align-items-center col-6 col-md-3 my-2">
                <label className="d-flex">
                  <input
                    type="checkbox"
                    name="allowTwitterAdvertisementCollect"
                    checked={formik.values.allowTwitterAdvertisementCollect}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="ml-2 text-nowrap">
                    {strings.register.platform_titles.twitter}
                  </span>
                </label>
              </dt>
            </dl>
          </div>
          <div
            id="termsSelect"
            className={clsx(
              'p-4 mt-4 mr-5 mb-4 bg-light border border-gray rounded w-100 text-left',
              {
                'border-warning border-warning-pop bg-warning': shouldAgreedToLatestTerms,
              }
            )}
          >
            <div>
              <label>
                <input
                  type="checkbox"
                  name="consent1"
                  value={formik.values.consent1}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span>
                  {strings.register.terms_consent1[0]} <TermsLink />
                </span>
                <FormikError name="consent1" formik={formik} />
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  name="consent2"
                  value={formik.values.consent2}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span>
                  {strings.register.terms_consent2[0]} <PrivacyLink /> <> </>
                  {strings.register.terms_consent2[2]}
                </span>
                <FormikError name="consent2" formik={formik} />
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  name="consent3"
                  value={formik.values.consent3}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span>
                  {strings.register.terms_consent3[0]} <PrivacyLink />
                </span>
                <FormikError name="consent3" formik={formik} />
              </label>
            </div>
          </div>
          <p>{strings.register.terms8}</p>
          <p>
            <span>
              {`${strings.register.terms1} `}
              <TermsLink />
              {` ${strings.register.terms3} `}
              <PrivacyLink />
            </span>
          </p>
        </section>
        <NavigationButtons
          state={state}
          transitionToPreviousState={transitionToPreviousState}
        />
      </form>
    </PageRegistrationContainer>
  );
};

import React from 'react';
import { Section } from './Section';
import { StatsBlock } from '../partials/statsBlock/StatsBlock';
import {
  getUserCountry,
  LoadingPlaceholder,
  strings,
  userHasPoliticalData,
} from '../../../shared';
import { isCountrySupported } from '../../../shared';
import { useResultsState } from '../../../context/ResultsContext';

const Wrapper = ({ children }) => {
  return (
    <section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '180px',
        }}
      >
        {children}
      </div>
    </section>
  );
};

export const SectionPersonalStats = () => {
  const { countries, adverts } = useResultsState();
  const userCountry = getUserCountry();

  const supportedCountry = isCountrySupported(userCountry, countries.records);

  // Show a loader if we are still trying to determine if there is any advert/supported country
  if (adverts.is_loading && adverts.is_empty) {
    return <LoadingPlaceholder />;
  }

  // Show nothing if the country is not supported and user has no adverts data
  if (!supportedCountry && !userHasPoliticalData(adverts.records)) {
    return (
      <Wrapper>
        <h5 className="subMessage">
          <p>
            {strings.results.no_country_explanation1 + ' '}
            {strings.results.no_country_explanation2}
          </p>
        </h5>
      </Wrapper>
    );
  }

  // Let users know that they will see something in the future as they use FB
  if (supportedCountry && !userHasPoliticalData(adverts.records)) {
    return (
      <Wrapper>
        <div
          className="card w-100 d-flex align-items-center bg-light my-4"
          style={{ border: 'medium solid #6d7074', fontSize: '1.1rem' }}
        >
          <div className="card-body text-center w-75">
            <p className="mb-4">{strings.results.no_results_explanation}</p>
            <p>
              {strings.results.no_country_explanation1 + ' '}
              <a href="#results_share_section">
                {strings.results.no_country_explanation2}
              </a>
              {' ' + strings.results.no_country_explanation3}
            </p>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Section>
      <div className="statbox headerBox">
        <div className="headerText">
          <StatsBlock />
        </div>
      </div>
    </Section>
  );
};

import React from 'react';

export const Debug = (props) => {
  return (
    <section
      className="debug container"
      style={{ fontSize: '0.8em', marginBottom: '60px' }}
    >
      <p>
        If you're having problems with the extension, then please get in touch
        via <a href="mailto:contact@whotargets.me">contact@whotargets.me</a>.
      </p>
      <p>
        The following is your current browser and your unique user identifier.
      </p>
      <code>
        {navigator.userAgent}
        <br />
        {props.general_token}
      </code>
    </section>
  );
};

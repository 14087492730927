import React from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingPlaceholder = () => (
  <div className="spinnerContainer my-4">
    <Spinner animation="grow" variant="secondary" size="sm" />
    <Spinner animation="grow" variant="secondary" size="sm" />
    <Spinner animation="grow" variant="secondary" size="sm" />
  </div>
);

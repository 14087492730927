import React from 'react';
import './partials.css';
import { Button, Container } from 'react-bootstrap';

export const BlockHeader = ({ props, style }) => {
  return (
    <h3 className="blockHeader" style={style}>
      {props && props.blockHeader}
    </h3>
  );
};

export const BlockHeaderInner = ({ props, style }) => {
  return (
    <h4 className="blockHeader" style={style}>
      {props && props.blockHeader}
    </h4>
  );
};

export const Block = ({ props, style, component }) => {
  if (component) {
    return (
      <div className="container blockContainer borderedBlock">{component}</div>
    );
  }
  const {
    blockHeader,
    blockInnerHeader,
    blockText1,
    blockText2,
    blockText3,
    buttonText1,
    buttonClass1,
    buttonHref1,
    action1,
    buttonText2,
    buttonClass2,
    buttonHref2,
    action2,
    buttonText3,
    buttonClass3,
    buttonHref3,
    action3,
    liText1,
    liText2,
    liText3,
  } = props;
  // console.log(props, style)

  return (
    <section>
      {blockHeader && (
        <Container>
          <BlockHeader props={{ blockHeader }} />
        </Container>
      )}

      {blockText1 && (
        <div className=" " style={style}>
          {blockInnerHeader && (
            <BlockHeaderInner
              props={{ blockHeader: blockInnerHeader }}
              style={{ width: '100%' }}
            />
          )}
          <div className="container blockContainer borderedBlock">
            <p>{blockText1}</p>
            {blockText2 && <p>{blockText2}</p>}
            {blockText3 && <p>{blockText3}</p>}
            {buttonText1 && (
              <div className="btnContainer">
                <Button
                  variant="primary"
                  className={buttonClass1}
                  href={buttonHref1}
                  onClick={() => action1()}
                >
                  {buttonText1}
                </Button>
                {buttonText2 && (
                  <Button
                    variant="outline-primary"
                    className={buttonClass2}
                    href={buttonHref2}
                    onClick={() => action2()}
                  >
                    {buttonText2}
                  </Button>
                )}
                {buttonText3 && (
                  <Button
                    variant="outline-primary"
                    className={buttonClass3}
                    href={buttonHref3}
                    onClick={() => action3()}
                  >
                    {buttonText3}
                  </Button>
                )}
              </div>
            )}
            {liText1 && (
              <ul className="bulletUl">
                <li>{liText1}</li>
                {liText2 && <li>{liText2}</li>}
                {liText3 && <li>{liText3}</li>}
              </ul>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

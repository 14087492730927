import { format } from 'date-fns';
import download from 'downloadjs';
import { config } from '../config';

export const fetchMyImpressionCsv = async () => {
  const token = window.localStorage
    .getItem('general_token')
    .replace(/^"|"$/g, '');
  return window
    .fetch(`${config.REACT_APP_DATA_API_BASE}/my-impressions-csv`, {
      headers: {
        Authorization: token,
      },
    })
    .then(async (res) => {
      const blob = await res.blob();
      download(
        blob,
        `wtm-ads_${token.slice(0, 16)}_${format(new Date(), 'yyyy-MM-dd')}.csv`,
        'text/csv'
      );
    })
    .catch((err) => {});
};

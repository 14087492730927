export const researchStudiesMap = {
  // PL: [
  //   {
  //     name: 'Manchester Studies',
  //     link: 'https://ir96l7a6vsf.typeform.com/to/RoA2fDzU#wtm_key=',
  //   },
  // ],
  GB: [
    {
      name: 'ADFUND - Monetisation of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  US: [
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  DE: [
    {
      name: 'ZDF Magazin Royale - Weitere Recherchen',
      key: 'zdf',
      link: 'https://ir96l7a6vsf.typeform.com/to/zd93gHQd#wtm_key=',
    },
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  FR: [
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  HU: [
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  ES: [
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
  CZ: [
    {
      name: 'ADFUND - Monetization of disinformation study',
      key: 'adfund',
      link: 'https://ir96l7a6vsf.typeform.com/to/Mm5nc5nI#wtm_key=',
    },
  ],
};

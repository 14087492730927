import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { strings } from '../../../../shared';

const details = [
  {
    route: 'about',
    name: strings.nav.about,
    externalLink: 'https://whotargets.me/en/about-who-targets-me/',
  },
  {
    route: 'projects',
    name: 'Projects',
    externalLink: 'https://whotargets.me/en/projects/',
  },
  {
    route: 'trends',
    name: 'Trends',
    externalLink: 'https://trends.whotargets.me/countries',
  },
  {
    route: 'Policy/analysis',
    name: strings.nav.policy_analysis,
    externalLink: 'https://whotargets.me/en/category/policy/',
  },
  {
    route: 'Services',
    name: strings.nav.services,
    externalLink: 'https://whotargets.me/en/consulting/',
  },
  {
    route: 'newsletter',
    name: strings.nav.newsletter,
    externalLink: 'https://whotargets.me/en/newsletter/',
  },
  {
    route: 'donate',
    name: strings.nav.donate,
    externalLink: 'https://whotargets.me/en/donate/',
  },
];

const blackListedRoutes = [
  '/registration/language',
  '/registration/consent',
  '/registration/about-me',
  '/registration/share',
];

export const NavHeader = () => {
  const location = useLocation();

  return (
    <div className="mb-5" style={{ backgroundColor: 'rgb(241 245 249)' }}>
      <Container>
        <Row>
          <Col xs={blackListedRoutes.includes(location.pathname) ? 11 : 1}>
            <div className="statbox headerBox">
              <div className="headerImg py-1">
                <Nav.Link href="/">
                  <img
                    src="wtm_logo_128.png"
                    alt="Who Targets Me?"
                    style={{ width: '56px' }}
                  />
                </Nav.Link>
              </div>
            </div>
          </Col>
          {!blackListedRoutes.includes(location.pathname) && (
            <Col xs={11} className="navHeader">
              <Nav activeKey="/">
                {details.map((d) => {
                  if (d.route === 'donate') {
                    return (
                      <div className="nav-item" key={`route-${d.route}`}>
                        <a
                          className="px-3 text-danger"
                          href={d.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: '16px' }}
                        >
                          {d.name}
                        </a>
                      </div>
                    );
                  } else if (d.externalLink) {
                    return (
                      <div className="nav-item" key={`route-${d.route}`}>
                        <a
                          className="px-3 text-dark"
                          href={d.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: '16px' }}
                        >
                          {d.name}
                        </a>
                      </div>
                    );
                  } else {
                    return (
                      <Nav.Item key={`route-${d.route}`}>
                        <Nav.Link href={'/' + d.route}>{d.name}</Nav.Link>
                      </Nav.Item>
                    );
                  }
                })}
              </Nav>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

import { MD5 } from 'crypto-js';
import { format } from 'date-fns';
import _, { keyBy, range } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Pagination, Table } from 'react-bootstrap';
import { usePagination, useTable } from 'react-table';
import { fetchMyImpressionCsv } from '../../../../api';
import { useResultsState } from '../../../../context/ResultsContext';
import { app, strings, getUserCountry } from '../../../../shared';
import { filterEntitiesByEntityId } from '../../../../shared';
import { RationaleView } from '../../RationaleView';
import {
  Section,
  SectionBody,
  SectionContent,
  SectionFooter,
  SectionTitle,
} from '../Section';
import { FactCheckAction } from './FactCheckAction';
import './SectionAdsTable.css';

const userHasAds = (ads, isEntitiesEmpty) => {
  return ads && ads.length !== 0 && !isEntitiesEmpty;
};

const FACTCHECKING_COUNTRIES = [];

export const SectionAdsTable = () => {
  const { entities_summary, ...rest } = useResultsState();
  const userCountry = getUserCountry();
  const adverts = rest.adverts.records;

  const [factcheckedAds, setFactcheckedAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const adsWithHashCount = useMemo(() => {
    // Use hashes to find duplicate ads
    // create lookup table
    const hashes = {};

    // create hash using advertiserId and text
    const adsWithHash = adverts.map((ad) => {
      const contentHash = MD5(
        ad.advertiserId + ad.postText?.join('')
      ).toString();

      // add to lookup table
      if (!hashes[contentHash]) hashes[contentHash] = [];
      hashes[contentHash].push(ad.postId);

      // return ad with hash ref
      return { ...ad, contentHash };
    });

    // add count by hash
    return adsWithHash.map((ad) => ({
      ...ad,
      viewCount: hashes[ad.contentHash].length,
    }));
  }, [adverts]);

  async function fetchFactcheckData(hc) {
    try {
      setIsLoading(true);
      const response = await app.service('factcheck-submissions').find({
        query: {
          contentHash: {
            $in: hc.map((a) => a.contentHash),
          },
        },
      });

      setIsLoading(false);
      setFactcheckedAds(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchFactcheckData(adsWithHashCount);
  }, [adsWithHashCount]);

  const data = useMemo(() => {
    const keyedFactcheckedAds = keyBy(factcheckedAds, 'contentHash');

    return _(adsWithHashCount)
      .sortBy('postLoggedAt')
      .map((i) => ({
        ...i,
        submissionStatus: keyedFactcheckedAds[i.contentHash]?.submissionStatus,
        submissionResult: keyedFactcheckedAds[i.contentHash]?.submissionResult,
        submissionResultUrl:
          keyedFactcheckedAds[i.contentHash]?.submissionResultUrl,
      }))
      .uniqBy('contentHash')
      .reverse();
  }, [adsWithHashCount, factcheckedAds]);

  const columns = useMemo(() => {
    async function handleReportSubmit(row) {
      fetchFactcheckData();
    }

    const columns = [
      {
        Header: strings.ads_table.date,
        accessor: 'postLoggedAt',
        Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: strings.ads_table.advertiser,
        accessor: (row) => row,
        Cell: ({ value }) => {
          const [entity] = filterEntitiesByEntityId(
            entities_summary.records,
            value.entityId
          );

          return (
            <span
              style={{
                borderBottom: `2px solid ${entity?.color}`,
                fontWeight: 700,
              }}
            >
              <a className="noLink" href="#partySummary">
                {value.advertiserName}
              </a>
            </span>
          );
        },
      },
      { Header: strings.ads_table.text, accessor: 'postText' },
      {
        Header: strings.ads_table.targeting,
        // Output text is an amalgam of multiple columns,
        // we pass set the whole row in the accessor and format in the Cell
        accessor: (row) => row,
        Cell: ({ value }) => RationaleView({ ad: value }),
      },
      {
        Header: strings.ads_table.view_ad,
        accessor: (row) => row,
        Cell: ({ value }) => {
          return (
            <a
              style={{
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                textDecoration: 'underline',
                color: 'gray',
              }}
              href={value.adLibraryUrl}
              target="_blank"
              rel="noreferrer"
            >
              View on Ad Library
            </a>
          );
        },
      },
    ];

    if (FACTCHECKING_COUNTRIES.includes(userCountry)) {
      columns.push({
        Header: strings.ads_table.fact_check_column_heading,
        accessor: (row) => row,
        Cell: ({ value }) => (
          <FactCheckAction
            advert={value}
            onClickHandler={handleReportSubmit}
            isLoading={isLoading}
          />
        ),
      });
    }

    return columns;
  }, [entities_summary.records, isLoading, userCountry]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageSize: 5 } },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  // Load of pages crap for use in pagination
  const prevIndex = pageIndex - 1;
  const nextIndex = pageIndex + 1;
  const lowestPageIndex = prevIndex < 0 ? 0 : prevIndex;
  const highestPageIndex =
    nextIndex >= pageCount - 1 ? pageCount - 1 : nextIndex;
  const paginationRange = range(lowestPageIndex, highestPageIndex + 1);

  if (!userHasAds(adverts, entities_summary.is_empty)) return '';

  return (
    <Section>
      <SectionTitle>{strings.ads_table.ads_block_header}</SectionTitle>
      <SectionBody>
        <SectionContent>
          <Table {...getTableProps()} className="adsTable">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{
                            width: cell.column.id === 'postText' ? '50%' : '',
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* urgh, pagination is a pain in the butt */}
          <Pagination>
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />

            {lowestPageIndex > 0 && (
              <Pagination.Item onClick={() => gotoPage(0)}>1</Pagination.Item>
            )}

            {lowestPageIndex > 1 && <Pagination.Ellipsis />}

            {paginationRange.map((idx) => {
              return (
                <Pagination.Item
                  key={idx}
                  onClick={() => gotoPage(idx)}
                  active={pageIndex === idx}
                >
                  {idx + 1}
                </Pagination.Item>
              );
            })}

            {highestPageIndex < pageCount - 2 && <Pagination.Ellipsis />}

            {highestPageIndex + 1 < pageCount && (
              <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>
                {pageCount}
              </Pagination.Item>
            )}

            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </SectionContent>

        <SectionFooter>
          <div className="pb-3 sectionFooter">
            <span>{strings.ads_table.download_ads_info} </span>
            <Button className="btn-sm" onClick={fetchMyImpressionCsv}>
              {strings.ads_table.download_ads_button}
            </Button>
          </div>
        </SectionFooter>
      </SectionBody>
    </Section>
  );
};

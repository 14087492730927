import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BlockHeader } from '../partials/blocks';
import './Section.css';

/**
 * Section
 *  SectionTitle
 *  SectionBody
 *    SectionLead
 *    SectionContent
 *    SectionFooter
 */

export const Section = (props) => {
  return <section>{props.children}</section>;
};

export const SectionTitle = ({ children }) => (
  <BlockHeader
    props={{ blockHeader: children }}
    style={{ textAlign: 'center' }}
  />
);

/* mainContent borderedBlock noRadius mt-0 */
export const SectionBody = (props) => (
  <Container className="sectionBody borderedBlock">{props.children}</Container>
);

export const SectionLead = (props) => (
  <Row className="lead" style={{ marginBottom: '15px' }}>
    {props.children}
  </Row>
);

export const SectionContent = (props) => (
  <Row className="content">{props.children}</Row>
);

export const SectionFooter = (props) => (
  <Row className="footer">
    <footer>{props.children}</footer>
  </Row>
);

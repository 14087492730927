import React, { useEffect } from 'react';
import {
  fetchCountries,
  fetchEntitiesSummary,
  fetchUserAdverts,
} from '../../api';
import {
  useResultsDispatch,
  useResultsState,
} from '../../context/ResultsContext';
import { getUserCountry } from '../../shared';
import { subMonths } from 'date-fns';

const handleEntitiesSummary = async (country, dispatch) => {
  const entitiesSummary = await fetchEntitiesSummary(country);
  dispatch({ type: 'FETCH_ENTITIES_SUMMARY', payload: entitiesSummary });
};

const handleCountries = async (dispatch) => {
  const countries = await fetchCountries({ query: { $limit: -1 } });
  dispatch({ type: 'FETCH_COUNTRIES', payload: countries });
};

const handleAdverts = async (dispatch) => {
  const postCreatedAt = subMonths(new Date(), 3);
  const adverts = await fetchUserAdverts({
    query: {
      postLoggedAt: { $gte: postCreatedAt },
    },
  });
  dispatch({ type: 'FETCH_ADVERTS', payload: adverts });
};

export const withResultsContext = (Component) => {
  return function WrappedComponent(props) {
    const state = useResultsState();
    const dispatch = useResultsDispatch();

    const country = getUserCountry();

    useEffect(() => {
      const fetch = async () => {
        const promises = [];
        if (state && dispatch && country) {
          const { countries, entities_summary, adverts } = state;

          if (
            countries.is_empty &&
            !countries.is_loading &&
            countries.records.length === 0
          ) {
            dispatch({ type: 'LOADING_COUNTRIES' });
            promises.push(handleCountries(dispatch));
          }

          if (
            entities_summary.is_empty &&
            !entities_summary.is_loading &&
            entities_summary.records.length === 0
          ) {
            dispatch({ type: 'LOADING_ENTITIES_SUMMARY' });
            promises.push(handleEntitiesSummary(country, dispatch));
          }

          if (
            adverts.is_empty &&
            !adverts.is_loading &&
            adverts.records.length === 0
          ) {
            dispatch({ type: 'LOADING_ADVERTS' });
            promises.push(handleAdverts(dispatch));
          }

          await Promise.all(promises);
        }
      };

      fetch();
    }, [country, state, dispatch]);

    return <Component {...props} />;
  };
};

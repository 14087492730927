import React from 'react';
import { Button } from 'react-bootstrap';
import { strings } from '../../../../shared';

const SUBMISSION_STATUS_PENDING = 'PENDING';
const SUBMISSION_STATUS_REJECTED = 'REJECTED';
const SUBMISSION_STATUS_ACCEPTED = 'ACCEPTED';
const SUBMISSION_STATUS_COMPLETED = 'COMPLETED';

export const FactCheckAction = (props) => {
  const { onClickHandler, advert, isLoading } = props;

  switch (advert.submissionStatus) {
    case SUBMISSION_STATUS_PENDING:
      return (
        <span>{strings.ads_table.fact_check_submission_status_pending}</span>
      );

    case SUBMISSION_STATUS_REJECTED:
      return (
        <span>{strings.ads_table.fact_check_submission_status_rejected}</span>
      );

    case SUBMISSION_STATUS_ACCEPTED:
      return (
        <span>{strings.ads_table.fact_check_submission_status_accepted}</span>
      );

    case SUBMISSION_STATUS_COMPLETED:
      return (
        <>
          <div>{strings.ads_table.fact_check_submission_status_completed}</div>
          <div>{advert.submissionResult}</div>
          <div>
            <a
              href={advert.submissionResultUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {
                strings.ads_table
                  .fact_check_submission_status_completed_view_link
              }
            </a>
          </div>
        </>
      );

    default:
      return (
        <Button
          size="sm"
          onClick={() => onClickHandler(advert)}
          disabled={isLoading}
        >
          {strings.ads_table.fact_check_request}
        </Button>
      );
  }
};

import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * interface options {
 *  value: String
 *  label: String
 * }
 */

export const Select = (props) => {
  const { options, selected, selectedDefault, sendState } = props;

  const handleChange = (e) => {
    sendState({
      selected: e.target.value,
    });
  };

  const value = selected || selectedDefault;
  const selectedOption = options.filter((i) => i.value === value)[0];

  const disabled = options.length < 2;

  if (disabled) {
    return <div>{selectedDefault}</div>;
  }

  return (
    <Form.Group controlId="geoSelect">
      <Form.Control
        as="select"
        onChange={handleChange}
        defaultValue={selectedOption?.value}
      >
        <option value={selectedOption?.value} key="option-default">
          {selectedOption?.label || value}
        </option>

        <option key="option-default-empty" value="" disabled>
          _________
        </option>

        {options.map((op, i) => (
          <option value={op.value} key={`option-${i}`}>
            {op.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  selected: PropTypes.string,
  selectedDefault: PropTypes.string,
  sendState: PropTypes.func,
};

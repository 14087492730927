import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SharingBlock, strings } from '../../shared';
import { useHistory } from 'react-router-dom';
import { Block } from '../PageResults/partials/blocks';

export const PageShare = ({ userCountry }) => {
  const history = useHistory();

  return (
    <div className="container">
      <div className="mt-4">
        <Block
          component={<SharingBlock userCountryFromRegistration={userCountry} />}
        />
      </div>
      <div className="row justify-content-center sharingContainer">
        <div className="col-12 col-md-8 mb-2 sharingSubMsg text-center">
          {strings.share.cost_share}
        </div>
        <div className="mt-4">
          <Container>
            <Row>
              <Col>
                <Button
                  variant="outline-danger"
                  className="buttonDonate"
                  target="_blank"
                  href="https://whotargets.me/en/donate/"
                >
                  {strings.share.donate}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  className="skipButton"
                  onClick={() => {
                    window.localStorage.removeItem(
                      'registration_machine_state'
                    );
                    history.push('/');
                    window.location.reload();
                  }}
                >
                  {strings.utils.skip} {String.fromCharCode('187')}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

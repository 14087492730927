import React from 'react';
import { PageDeleteDataRequest } from '../PageDeleteData/PageDeleteDataRequest';
import { PageDeleteDataSuccess } from '../PageDeleteData/PageDeleteDataSuccess';
import NavHeader from './partials/NavHeader';
import { findSafari, storage } from '../../shared';
import PageExtensionNotInstalled from '../PageExtensionNotInstalled';
import PageRegistration from '../PageRegistration';
import PageResults from '../PageResults';
import { InstallExtension } from './partials/HelperScreens/helperScreens';

import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { ResultsProvider } from '../../context/ResultsContext';
import PageAboutMe from '../PageAboutMe';
import PageConsent from '../PageConsent';
import PageLanguage from '../PageLanguage';
import PageResearchStudies from '../PageResearchStudies';
import PageShare from '../PageShare';
import { browserHistory } from './browserHistory';
import { getUser } from '../../shared';

import './Shell.css';

const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default class Shell extends React.Component {
  constructor() {
    super();
    this.state = {
      access_token: null,
      token_loaded: false,
      updating_profile: false,
      extensionInstalled: null,
    };

    this.user = getUser();
  }

  componentDidMount() {
    this.checkToken();
    this.checkExtensionExists();
  }

  postTokenMessage = () => {
    window.postMessage(
      {
        storeUserToken: true,
        token: JSON.parse(localStorage.getItem('general_token')),
      },
      '*'
    );
  };

  updateYGTab = () => {
    window.postMessage({ updateYGTab: true }, '*');
  };

  updateProfile = (condition) => {
    this.setState({ updating_profile: condition });
  };

  handleRegistrationComplete = () => {
    this.checkToken();
    this.checkExtensionExists();
    window.location = '/';
  };

  ShowResults = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    this.postTokenMessage();

    const localStorageToken = JSON.parse(localStorage.getItem('general_token'));
    if (localStorageToken && localStorageToken.length !== 0) {
      this.updateYGTab();
    }

    const { updating_profile, extensionInstalled } = this.state;

    if (!extensionInstalled || token === 'clearwtmdata') {
      storage.deleteState();
      return <InstallExtension />;
    }

    const tokenValid = (url) => {
      const stopWords = ['clearwtmdata', 'http:', 'https:'];
      let t = true;
      stopWords.forEach((w) => {
        if (url.indexOf(w) > -1) {
          t = false;
        }
      });
      return t;
    };

    if (token && tokenValid) {
      this.setToken(token);
    }

    if (!window.localStorage.getItem('general_token')) {
      return <Redirect to="/registration/language" />;
    }

    this.user.syncWithExtension();

    if (this.user.shouldReconsent) {
      return <Redirect to="/consent" />;
    }

    return (
      <ResultsProvider>
        <PageResults
          updateProfile={this.updateProfile}
          updating_profile={updating_profile}
        />
      </ResultsProvider>
    );
  };

  render() {
    if (!this.state.token_loaded) {
      return null;
    }
    const { ShowResults, state } = this;
    const { extensionInstalled } = state;
    const clearwtmdata = window.location.href.indexOf('clearwtmdata') > -1;

    // Initial state before check is null
    if (extensionInstalled === null) {
      return <PageExtensionNotInstalled />;
    }

    // If no extension, then always return to root and recommend install
    if (extensionInstalled === false) {
      return (
        <Router>
          <Redirect to="/" />
          <InstallExtension />
        </Router>
      );
    }

    return (
      <Router history={browserHistory}>
        <div>
          {!clearwtmdata && <NavHeader />}
          <Switch>
            <Route path="/settoken/:token" children={<ShowResults />} />
            <Route path="/delete-data-request">
              <PageDeleteDataRequest />
            </Route>
            <Route path="/delete-data-success">
              <PageDeleteDataSuccess />
            </Route>
            <Route path="/registration/:page_path">
              <ResultsProvider>
                <PageRegistration />
              </ResultsProvider>
            </Route>
            <Route path="/language">
              <PageLanguage
                transitionToNextState={() => browserHistory.push('/')}
                transitionToPreviousState={null}
              />
            </Route>
            <Route path="/about-me">
              <ResultsProvider>
                <PageAboutMe
                  transitionToNextState={() => browserHistory.push('/')}
                  transitionToPreviousState={null}
                />
              </ResultsProvider>
            </Route>
            <Route path="/consent">
              <ResultsProvider>
                <PageConsent
                  transitionToNextState={() => browserHistory.push('/')}
                  transitionToPreviousState={null}
                />
              </ResultsProvider>
            </Route>
            <Route path="/share">
              <PageShare />
            </Route>
            <Route path="/research-studies/:country">
              <PageResearchStudies />
            </Route>
            <Route path="/" children={<ShowResults />} />
          </Switch>
        </div>
      </Router>
    );
  }

  setToken = (token) => {
    storage.saveState('general_token', token).then((res) => {
      window.location.replace('/');
    });
  };

  // if extension doesn't exist or was uninstalled but somehow token wasn't deleted,
  // prevent registration from this page or resutls review
  // TODO: indentify in Safari
  checkExtensionExists = async () => {
    await delay(1000);

    const extensionScript = document.querySelector('[src*="overload.js"]');
    const safariBrowser = findSafari();
    // const localDevelopment = window.location.href.indexOf('localhost') > -1;
    if (extensionScript || safariBrowser) {
      this.setState({ extensionInstalled: true });
    }
  };

  checkToken = async () => {
    await storage
      .loadState(['general_token'])
      .then((r) => {
        const general_token = r ? r.general_token : null;
        if (general_token) {
          // reload page after user has updated profile to grab new user profile
          if (this.state.updating_profile) {
            storage.saveState('wtmdata', null);
          }

          this.setState({
            access_token: general_token,
            token_loaded: true,
            updating_profile: false,
          });
        } else {
          this.setState({ token_loaded: true, updating_profile: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ token_loaded: true, updating_profile: false });
      });
  };
}

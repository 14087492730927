import feathers from '@feathersjs/client';
import { config } from '../../config';

const app = feathers();
const restClient = feathers.rest(`${config.REACT_APP_DATA_API_BASE}`);
const token = JSON.parse(window.localStorage.getItem('general_token'));

app.configure(
  restClient.fetch(window.fetch, {
    headers: {
      Authorization: token,
    },
  })
);

window.app = app;

export { app };

import LocalizedStrings from 'react-localization';
import { storage } from './storage.js';
import en from '../../locale/en.json';
import { getLocaleData } from './getLocaleData';

// setup default 'en'
export const locales = { en };
export const strings = new LocalizedStrings(locales);

export const changeLocale = (locale) => {
  storage.saveState('language', locale);
  strings.setLanguage(locale);
};

getLocaleData();

export function setLanguageFromStorage() {
  storage
    .loadState(['language'])
    .then((r) => {
      strings.setLanguage(r.language);
    })
    .catch(() => {
      storage.saveState('language', 'en');
      strings.setLanguage('en');
    });
}
setLanguageFromStorage();

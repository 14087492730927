export const buildCountriesMap = (countries) => {
  const map = {};

  countries.forEach((country) => {
    // alpha2 is the country's acronym all in uppercase
    const alpha2 = country.alpha2.toUpperCase();
    map[alpha2] = country.name;
  });

  return map;
};

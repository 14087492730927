import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { storage, strings, app, LoadingPlaceholder } from '../../shared';

import {
  FormikError,
  NavigationButtons,
  PageRegistrationContainer,
} from '../../shared';
import { useHistory, useLocation } from 'react-router-dom';

export const PageLanguage = ({
  state,
  transitionToPreviousState,
  transitionToNextState,
}) => {
  const location = useLocation();
  const history = useHistory();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      language: JSON.parse(localStorage.getItem('language')) || 'en',
    },
    validationSchema: Yup.object({
      language: Yup.string().required(strings.errors.required),
    }),
    onSubmit: (values) => {
      strings.setLanguage(values.language);
      storage.saveState('language', values.language);

      transitionToNextState(values);
    },
  });

  // Redirect users to /registration/language if an account is not created
  if (location.pathname === '/language' && !localStorage.getItem('wtmdata')) {
    history.push('/registration/language');
  }

  const [languages, setLanguages] = useState();
  const [loadingLanguages, setLoadingLanguages] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoadingLanguages(true);
      const res = (await app.service('languages').find()).filter(
        (l) => l.translated_percent >= 50
      );
      setLanguages(res);
      setLoadingLanguages(false);
    }
    fetchData();
  }, []);

  if (loadingLanguages) {
    return <LoadingPlaceholder />;
  }

  return (
    <PageRegistrationContainer>
      <form onSubmit={formik.handleSubmit}>
        <ol
          className="regMessage"
          style={{ paddingLeft: '2%', fontSize: '1.2em' }}
        >
          <li>
            <h5 className="resetFontSize">
              {strings.register.select_language}
            </h5>
          </li>
        </ol>
        <div className="text-left" style={{ width: '50%' }}>
          <select
            as="select"
            name="language"
            className="form-control"
            value={formik.values.language}
            onChange={formik.handleChange}
            disabled={!languages}
          >
            {languages &&
              languages.map((item) => (
                <option value={item.code} key={'lang_' + item.code}>
                  {item.native} / {item.name}
                </option>
              ))}
          </select>

          <FormikError name="languages" formik={formik} />

          <div>
            <a
              href="https://hosted.weblate.org/projects/whotargetsme/wtm-results-frontend/"
              target="_blank"
              rel="noreferrer"
            >
              {strings.register.help_us_translate}
            </a>
          </div>
        </div>
        <NavigationButtons
          state={state}
          transitionToPreviousState={transitionToPreviousState}
        />
      </form>
    </PageRegistrationContainer>
  );
};

/**
 * To find the percentage a number is of the whole
 *
 * @param {Number} amount the portion of the total
 * @param {Number} total the total amount, containing the portion
 * @returns {Number} Number
 */
export function getPercentageOfTotal(amount, total) {
  return ((amount / total) * 100).toFixed(0);
}

/**
 * To find the percentage ot the remainder. Like, the inverse
 *
 * @param {Number} amount the portion of the total
 * @param {Number} total the total amount, containing the portion
 * @returns {Number} Number
 */
export function getPercentageRemainOfTotal(amount, total) {
  return 100 - getPercentageOfTotal(amount, total);
}

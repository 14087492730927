import React from 'react';
import { strings } from '../../../../shared';
import { Button } from 'react-bootstrap';
import Logo from '../../wtm_logo_2020.png';
import Chrome from '../img/Chrome.png';
import Firefox from '../img/Firefox.png';
import Safari from '../img/Safari.png';
import Edge from '../img/Edge.png';

export const NotFound = () => {
  return (
    <div>
      <h2>Nothing here</h2>
    </div>
  );
};

export const InstallExtension = () => {
  const links = {
    Chrome: {
      url:
        'https://chrome.google.com/webstore/detail/who-targets-me/fcejbjalmgocomoinikjejnkimlnoljp?hl=en',
      logo: Chrome,
    },
    Firefox: {
      url:
        'https://addons.mozilla.org/en-US/firefox/addon/who-targets-me-firefox/',
      logo: Firefox,
    },
    Safari: {
      url: 'https://apps.apple.com/gb/app/who-targets-me/id1528704214',
      logo: Safari,
    },
    Edge: {
      url:
        'https://microsoftedge.microsoft.com/addons/detail/who-targets-me/fphblnblkkcngpagebnmllmacbeipinc',
      logo: Edge,
    },
  };

  let browser;
  let BrowserLogo;
  let url = 'https://whotargets.me/en/';

  if (!!window.InstallTrigger) {
    browser = 'Firefox';
  } else if (!!window.chrome) {
    browser = 'Chrome';
  } else if (!!window.safari) {
    browser = 'Safari';
  }
  if (browser === 'Chrome' && navigator.userAgent.indexOf('Edg') !== -1) {
    browser = 'Edge';
  }

  if (browser) {
    url = links[browser].url;
    BrowserLogo = links[browser].logo;
  }

  return (
    <div className="installExtensionContainer">
      <div className="installExtensionInner">
        <div>
          <img src={Logo} alt="Who Targets Me?" />
        </div>
        <p>{strings.helper_screens.no_extension1}</p>
        <p>
          <Button href={url} variant="outline-primary">
            <span>{`${strings.helper_screens.no_extension2} ${
              browser ? strings.utils.for : ''
            }`}</span>
            <span>
              {browser && (
                <>
                  <span>
                    <img src={BrowserLogo} alt="" className="browserImg" />
                  </span>
                  <span>{` ${browser ? browser : ''}`}</span>
                </>
              )}
            </span>
          </Button>
        </p>
        <p>{strings.helper_screens.no_extension3}</p>
        <br />
        <br />
        <br />
        <p>
          <a href="https://whotargets.me/en/">© Who Targets Me</a>
        </p>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { getUserCountry, researchStudiesMap, strings } from '../../../shared';
import { ResearchStudiesCard } from '../../PageResearchStudies/ResearchStudiesCard';
import { Section, SectionTitle } from './Section';

export const SectionJoinStudy = () => {
  const country = getUserCountry();
  const [generalToken] = useState(
    JSON.parse(localStorage.getItem('general_token')) || ''
  );

  const researchStudies = researchStudiesMap[country.toUpperCase()];

  return (
    <Section>
      <SectionTitle>
        {strings.campaigns.research_studies_results_page_header}
      </SectionTitle>
      {researchStudies &&
        researchStudies.map((study) => (
          <ResearchStudiesCard
            key={study.key}
            study={study}
            url={study.link}
            generalToken={generalToken}
          />
        ))}
    </Section>
  );
};

import React from 'react';
import { SharingBlock, strings } from '../../shared';
import { Block, BlockHeader } from './partials/blocks';

export const SharingSection = () => {
  return (
    <section>
      <BlockHeader
        props={{ blockHeader: strings.share.share_who }}
        style={{ textAlign: 'center' }}
      />
      <Block component={<SharingBlock />} />
    </section>
  );
};

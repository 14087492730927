import React, { useState } from 'react';
import { config } from '../../config';

export const LargeOrganizationalEntityImage = ({
  id,
  borderColor,
  shortName,
}) => {
  const [logoNotFound, setLogoNotFound] = useState(false);

  const imageProperties = {
    bucket: 'wtm-images-upload-production',
    key: `LOGO_${id}`,
    edits: {
      resize: {
        width: 200,
        height: 200,
        fit: 'cover',
      },
    },
  };

  if (!shortName) {
    return null;
  }

  if (logoNotFound) {
    return (
      <div
        className="circle"
        style={{
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          backgroundColor: borderColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <text
          textAnchor="middle"
          dy="-0.7em"
          dx="-1.1em"
          dominantBaseline="middle"
          style={{ color: 'white' }}
        >
          {shortName.toUpperCase()}
        </text>
      </div>
    );
  }

  return (
    <img
      className="heroImg"
      src={`${config.REACT_APP_CF_IMAGE_HOST}/${window.btoa(
        JSON.stringify(imageProperties)
      )}?v=0`}
      onError={() => setLogoNotFound(true)}
      style={{ borderColor: borderColor, borderWidth: 'medium' }}
      alt={shortName}
    />
  );
};

import { subDays } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  useResultsDispatch,
  useResultsState,
} from '../../context/ResultsContext.js';
import { strings } from '../../shared';
import { Loading } from '../Loading/index.js';
import {
  FormikError,
  handleUserForms,
  NavigationButtons,
  OrderedListForm,
  PageRegistrationContainer,
  standardUserObj,
  buildCountriesMap,
} from '../../shared';
import './styles.css';
import { fetchCountries } from '../../api/fetchCountries.js';

export const PageAboutMe = ({
  state,
  transitionToNextState,
  transitionToPreviousState,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { countries } = useResultsState();
  const dispatch = useResultsDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isUpdating] = useState(!state);
  const [userData, setUserData] = useState(null);
  const countriesMap = buildCountriesMap(countries.records);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gender: '',
      politicalAffiliation: userData?.politicalAffiliation || '',
      country: userData?.country || '',
      age: userData?.age || '',
      postcode: userData?.postcode || '',
    },
    validationSchema: Yup.object({
      country: Yup.string().required(strings.errors.required),
      postcode: Yup.string().required(strings.errors.required),
      gender: Yup.string().required(strings.errors.required),
      age: Yup.number(strings.errors.should_be_number)
        .required(strings.errors.required)
        .min(18, strings.errors.age_error),
    }),
    onSubmit: async (values) => {
      const { age, gender, country, postcode, politicalAffiliation } = values;
      const { termsConsent } = state?.context?.payload || '';

      setIsLoading(true);
      if (isUpdating) {
        const wtmdata = JSON.parse(localStorage.getItem('wtmdata'));
        const expiredWtmData = {
          ...wtmdata,
          lastUpdated: subDays(new Date(), 1),
        };
        localStorage.setItem('wtmdata', JSON.stringify(expiredWtmData));
      }
      const response = await handleUserForms(
        {
          ...standardUserObj,
          country: country,
          age,
          gender,
          postcode,
          survey: null,
          email: null,
          consent: termsConsent,
          politicalAffiliation: politicalAffiliation || 3,
        },
        location.pathname.includes('/registration') ? false : true
      );

      if (response.success) {
        localStorage.removeItem('wtmdata');
        localStorage.removeItem('registration_machine_state');

        // If the user is updating their profile, we don't need to add an event listener
        if (!location.pathname.includes('/registration')) {
          transitionToNextState(formik.values);
        } else {
          window.addEventListener('message', (e) => {
            /*
            Making this line backward-compatible since we can't time the release of the extension
            This is due to the extension having the possibility of using two different APIs, one the Backend API
            the other from data-api (The check for Backend API must be removed once the new version of extension has been release)
            */

            if (
              // The line below is for checking the response from data-api
              e.data?.registrationFeedback?.token ||
              // The line below is for checking the response from Backend API
              e.data?.registrationFeedback?.data.token
            ) {
              window.location.reload();
              transitionToNextState(formik.values);
              setIsLoading(false);
            }
          });
        }
      } else setError(true);
    },
  });

  const politicalAffiliationLabels =
    formik.values.country === 'US'
      ? strings.register.us_labels
      : strings.register.non_us_labels || [];

  useEffect(() => {
    if (isUpdating) {
      setUserData(JSON.parse(localStorage.getItem('wtmdata')).userData);
    }
  }, [isUpdating]);

  useEffect(() => {
    const fetch = async () => {
      // when in /registration/about-me, the countries list is loaded in the component before
      if (
        location.pathname.startsWith('/about-me') &&
        countries.records.length === 0 &&
        countries.is_empty
      ) {
        const countries = await fetchCountries({ query: { $limit: -1 } });
        dispatch({ type: 'FETCH_COUNTRIES', payload: countries });
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, countries]);

  // Redirect users to /registration/language if an account is not created
  if (location.pathname === '/about-me' && !localStorage.getItem('wtmdata')) {
    history.push('/registration/language');
  }

  if (isError) {
    return <Loading errorLoading={isError} />;
  }

  if (isLoading) {
    return (
      <h3 className="text-center mt-4 font-weight-bold">
        {strings.register.confirming}
      </h3>
    );
  }

  return (
    <PageRegistrationContainer>
      <form onSubmit={formik.handleSubmit}>
        <p style={{ fontSize: '1.2em' }}>
          {strings.register.about_me.page_description}
        </p>
        <ol style={{ fontSize: '1.2em' }}>
          <OrderedListForm
            heading={strings.register.enter_country}
            description={strings.register.about_me.country_description}
          >
            <label htmlFor="country" className="form-check-label resetFontSize">
              <select
                as="select"
                name="country"
                id="country"
                className="form-control"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" defaultChecked>
                  {strings.register.enter_country}
                </option>
                <option disabled>──────────────────────</option>
                {Object.keys(countriesMap)
                  .sort((a, b) => {
                    // Sort countries alphabetically
                    if (countriesMap[a] < countriesMap[b]) {
                      return -1;
                    }
                    if (countriesMap[a] > countriesMap[b]) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((key) => (
                    <option value={key} key={'countries_' + key}>
                      {countriesMap[key]}
                    </option>
                  ))}
              </select>
            </label>
            <FormikError name="country" formik={formik} />
          </OrderedListForm>

          <OrderedListForm
            heading={strings.register.postcode}
            description={strings.register.about_me.postcode_description}
          >
            <label
              htmlFor="postcode"
              className="form-check-label resetFontSize"
            >
              <input
                type="text"
                id="postcode"
                name="postcode"
                className="form-control"
                placeholder={strings.register.postcode}
                defaultValue={formik.values.postcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            <FormikError name="postcode" formik={formik} />
          </OrderedListForm>

          <OrderedListForm
            heading={strings.register.gender}
            description={strings.register.about_me.gender_description}
          >
            <div
              role="group"
              aria-labelledby="gender"
              className="resetFontSize"
            >
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  id="genderFemale"
                  className="form-check-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultChecked={
                    isUpdating && userData?.gender === 2 ? true : null
                  }
                />
                <label htmlFor="genderFemale" className="form-check-label">
                  {strings.register.female}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  id="genderMale"
                  className="form-check-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultChecked={
                    isUpdating && userData?.gender === 1 ? true : null
                  }
                />
                <label htmlFor="genderMale" className="form-check-label">
                  {strings.register.male}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  name="gender"
                  type="radio"
                  id="genderOther"
                  className="form-check-input"
                  value="0"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultChecked={
                    isUpdating && userData?.gender === 0 ? true : false
                  }
                />
                <label htmlFor="genderOther" className="form-check-label">
                  {strings.register.other}
                </label>
              </div>
              <FormikError name="gender" formik={formik} />
            </div>
          </OrderedListForm>

          <OrderedListForm
            heading={strings.register.years_of_age}
            description={strings.register.about_me.age_description}
          >
            <label htmlFor="age" className="form-check-label resetFontSize">
              <input
                id="age"
                name="age"
                type="text"
                className="form-control"
                value={formik.values.age}
                placeholder={strings.register.age}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            <FormikError name="age" formik={formik} />
          </OrderedListForm>

          <OrderedListForm
            heading={strings.register.political_affiliation}
            description={strings.register.about_me.political_views_description}
          >
            <div className="d-flex flex-column p-2 resetFontSize">
              <label className="form-check-label">
                <input
                  name="politicalAffiliation"
                  type="range"
                  className="w-100 pb-1 form-control-range"
                  min="0"
                  max="6"
                  step="1"
                  value={
                    // If user chooses 'no affiliation', push the slider back to 'Center'
                    formik.values.politicalAffiliation === '7' ||
                    formik.values.politicalAffiliation === 7
                      ? 3
                      : formik.values.politicalAffiliation
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </label>
              <ul className="d-flex justify-content-between w-100 p-2">
                {politicalAffiliationLabels.slice(1).map((label, value) => (
                  <li
                    key={`pa-${value}`}
                    className="d-flex justify-content-center position-relative text-center"
                    value={value}
                  >
                    <span className="position-absolute text-left">{label}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-left mt-5 resetFontSize">
              <label htmlFor="pa-7" className="form-check-label mt-4">
                <input
                  id="pa-7"
                  value={7}
                  checked={parseInt(formik.values.politicalAffiliation) === 7}
                  type="radio"
                  name="politicalAffiliation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span className="pl-2">{politicalAffiliationLabels[0]}</span>
              </label>
            </div>
            <FormikError name="politicalAffiliation" formik={formik} />
          </OrderedListForm>
        </ol>
        <NavigationButtons
          state={state}
          transitionToPreviousState={transitionToPreviousState}
        />
      </form>
    </PageRegistrationContainer>
  );
};

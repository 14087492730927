import React from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { Section, SectionBody, SectionContent, SectionTitle } from './Section';
import { OrganizationalEntityImage } from '../../../shared';
import { useResultsState } from '../../../context/ResultsContext';
import { getUserCountry, strings } from '../../../shared';

const isLastRow = (index, total) => {
  return index === total - 1;
};

const userHasAdvertData = (country, isEntitiesSummaryEmpty) => {
  return country || !isEntitiesSummaryEmpty;
};

const SectionPartySummary = () => {
  const country = getUserCountry();
  const { entities_summary } = useResultsState();

  if (!userHasAdvertData(country, entities_summary.is_empty)) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>{strings.party_summary.title}</SectionTitle>
      <SectionBody>
        <SectionContent>
          <Table id="partySummary">
            <thead>
              <tr>
                <th>{strings.party_summary.logo}</th>
                <th>{strings.party_summary.party}</th>
                <th>{strings.party_summary.short_name}</th>
                <th>{strings.party_summary.pages_tracked}</th>
              </tr>
            </thead>
            <tbody>
              {_.orderBy(entities_summary.records, 'name', ['asc']).map(
                (d, i) => {
                  const arrayLength = entities_summary.records.length;
                  return (
                    <tr
                      key={`party-summary-${i}`}
                      className={isLastRow(i, arrayLength) ? 'last-tr' : ''}
                    >
                      <td>
                        <div>
                          <OrganizationalEntityImage
                            id={d.id}
                            borderColor={d.color}
                            shortName={d.short_name}
                          />
                        </div>
                      </td>
                      <td>
                        <span style={{ borderBottom: `2px solid ${d.color}` }}>
                          {d.name}
                        </span>
                      </td>
                      <td>{d.short_name}</td>
                      <td>{d.advertisers_count}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </SectionContent>
      </SectionBody>
    </Section>
  );
};

export default SectionPartySummary;

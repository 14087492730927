export const getAdvertisersByParty = (adverts, entities) => {
  let map = {};

  entities.forEach((entity) => {
    const advert = adverts.find((a) => a.entityId === entity.id);

    // if no advertiser is associated with the entity, just add 0 as advertiserCount
    if (!advert) {
      map = {
        ...map,
        [entity.id]: {
          entityId: entity.id,
          advertiserName: entity?.short_name,
          shortName: entity?.short_name,
          color: entity?.color || 'black',
          advertiserCount: 0,
          advertiserIds: [],
        },
      };
      return;
    }

    if (map[entity.id]) {
      map = {
        ...map,
        [entity.id]: {
          ...map[entity.id],
          advertiserCount: ++map[entity.id].advertiserCount,
          advertiserIds: [...map[entity.id].advertiserIds, advert.advertiserId],
        },
      };
    } else {
      const [entity] = entities.filter(
        (entity) => entity.id === advert.entityId
      );
      map = {
        ...map,
        [entity.id]: {
          entityId: entity.id,
          advertiserName: entity?.short_name,
          shortName: entity?.short_name,
          color: entity?.color || 'black',
          advertiserCount: 1,
          advertiserIds: [advert.advertiserId],
        },
      };
    }
  });

  return Object.values(map);
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { strings } from '../../../shared';

const FooterLinkItem = ({ content, url }) => {
  const history = useHistory();

  return (
    <div className="mt-2">
      {url.includes('http') ? (
        <a href={url} className="text-dark" target="_blank" rel="noreferrer">
          {content}
        </a>
      ) : (
        <span
          role="button"
          className="text-dark"
          onClick={() => history.push(url)}
        >
          {content}
        </span>
      )}
    </div>
  );
};

const FooterHeading = ({ content }) => {
  return <h6 className="pb-2 font-weight-bold">{content}</h6>;
};

export const Footer = () => {
  return (
    <div className="custom-footer">
      <div className="container">
        <div
          className="row justify-center rounded-lg my-5"
          style={{ backgroundColor: '#3b82f6' }}
        >
          <div className="text-center text-white m-5">
            <h4 className="font-weight-bolder">{strings.newsletter.title}</h4>
            <p className="p-2">{strings.newsletter.description}</p>
            <button className="btn bg-white">
              {strings.newsletter.sign_up}
            </button>
          </div>
        </div>
      </div>

      <div className="bg-light row justify-content-around">
        <div>
          <div className="row">
            <div className="col-sm m-4">
              <img
                src="wtm_logo_128.png"
                style={{ height: '2em', width: '2em' }}
                className="mb-3"
                alt="Who Targets Me?"
              />

              <FooterHeading content="Who Targets Me" />

              <p className="mt-3">{strings.footer.motto}</p>
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="m-4 col-sm">
              <FooterHeading content={strings.update.account_settings} />

              <FooterLinkItem
                content={strings.update.update_consent}
                url={'/consent'}
              />
              <FooterLinkItem
                content={strings.update.update_profile}
                url={'/about-me'}
              />
              <FooterLinkItem
                content={strings.delete.update_language}
                url={'/language'}
              />
              <FooterLinkItem
                content={strings.delete.delete_data}
                url={'/delete-data-request'}
              />
            </div>

            <div className="m-4 col-sm">
              <FooterHeading content={strings.nav.about_us} />

              <FooterLinkItem
                content={strings.nav.about}
                url="https://whotargets.me/en/about/"
              />
              <FooterLinkItem
                content={strings.nav.careers_and_volunteering}
                url={'https://whotargets.me/en/careers-and-volunteering/'}
              />
              <FooterLinkItem
                content={strings.nav.get_in_touch}
                url="https://whotargets.me/en/get-in-touch/"
              />
            </div>

            <div className="m-4 col-sm">
              <FooterHeading content={strings.links.privacy} />

              <FooterLinkItem
                content={strings.links.privacy_policy}
                url="https://whotargets.me/privacy-policy"
              />
              <FooterLinkItem
                content={strings.links.terms}
                url="https://whotargets.me/terms"
              />
              <FooterLinkItem
                content={strings.links.impressum}
                url="https://whotargets.me/en/impressum"
              />
            </div>

            <div className="m-4 col-sm">
              <FooterHeading content="Social" />

              <FooterLinkItem
                content="Twitter/X"
                url={'https://twitter.com/WhoTargetsMe'}
              />
              <FooterLinkItem
                content="Threads"
                url={'https://www.threads.net/@whotargetsme'}
              />
              <FooterLinkItem
                content="BlueSky"
                url={'https://bsky.app/profile/whotargetsme.bsky.social'}
              />
              <FooterLinkItem
                content="Facebook"
                url={'https://www.facebook.com/whotargetsme'}
              />
              <FooterLinkItem
                content="Substack"
                url={'https://substack.com/@whotargetsme'}
              />
            </div>
          </div>

          <div className="row">
            <div className="text-center md:text-right m-4 col-sm">
              <span className="text-secondary">{strings.delete.uninstall}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Button } from 'react-bootstrap';
import { strings } from '../../shared';
import { Debug } from '../PageResults/Debug.js';
import { Footer } from '../PageResults/partials/Footer.js';
import { removeUser } from '../../api';

export const PageDeleteDataRequest = () => {
  const history = useHistory();

  const [generalToken, setGeneralToken] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const general_token = JSON.parse(localStorage.getItem('general_token'));
      setGeneralToken(general_token);
    };

    fetchData();
  }, []);

  const confirmRemoveWTM = (e) => {
    localStorage.clear();
    window.postMessage({ deleteWTMUser: true }, '*');
    history.push('/delete-data-success');
  };

  const confirmDeleteData = async (e) => {
    const token = JSON.parse(localStorage.getItem('general_token'));
    try {
      await removeUser(token);
      localStorage.clear();
      history.push('/delete-data-success');
      window.postMessage({ deleteWTMUser: true }, '*');
    } catch (error) {}
  };

  return (
    <div className="pageResults">
      <Row>
        <div>
          <div className="emptyView">
            <h5 className="deleteHeader">{strings.delete.delete_request}</h5>
            <div className="deleteRequestContainer">
              <div className="column">
                <Button
                  variant="outline-success"
                  className="buttonGreen"
                  onClick={() => history.push('/')}
                >
                  {strings.utils.cancel}
                </Button>
                <p>{strings.delete.cancel_message}</p>
              </div>
              <div className="column">
                <Button
                  variant="outline-danger"
                  className="buttonRed"
                  onClick={confirmRemoveWTM}
                >
                  {strings.delete.remove_WTM}
                </Button>
                <p>{strings.delete.remove_WTM_message}</p>
                <p>{strings.delete.remove_WTM_message2}</p>
              </div>
              <div className="column">
                <Button
                  variant="outline-danger"
                  className="buttonRed"
                  onClick={confirmDeleteData}
                >
                  {strings.delete.delete_data}
                </Button>
                <p>{strings.delete.delete_data_message}</p>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <Debug general_token={generalToken} />
      </Row>

      <Footer view="delete_request" />
    </div>
  );
};

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { strings } from '../../shared';

export const NavigationButtons = ({
  state,
  transitionToPreviousState,
  transitionToNextState = null,
}) => {
  const history = useHistory();
  const location = useLocation();

  if (!transitionToNextState && !location.pathname.includes('/registration')) {
    return (
      <div className="mt-4">
        <button
          type="button"
          className="btn buttonBack"
          onClick={() => history.push('/')}
        >
          {strings.utils.back}
        </button>
        <button
          type="submit"
          style={{ width: '150px' }}
          className="btn btn-primary"
        >
          {strings.utils.next + ' ' + String.fromCharCode('187')}
        </button>
      </div>
    );
  }

  return (
    <div className="mt-4">
      {!state?.matches('language') && (
        <button
          onClick={() => transitionToPreviousState()}
          type="button"
          className="btn buttonBack"
          style={{ width: '150px' }}
        >
          {strings.utils.back}
        </button>
      )}
      <button
        type="submit"
        style={{ width: '150px' }}
        className="btn btn-primary"
        onClick={() => transitionToNextState}
      >
        {strings.utils.next + ' ' + String.fromCharCode('187')}
      </button>
    </div>
  );
};

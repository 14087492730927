import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { fetchUserTargetingSummary } from '../../../api/fetchUserTargetingSummary';
import { useResultsState } from '../../../context/ResultsContext';
import { filterEntitiesByEntityId, strings } from '../../../shared';
import { Section, SectionBody, SectionContent, SectionTitle } from './Section';

const getTableRowBorderStyle = (shouldAddBorder) => {
  return {
    borderBottomStyle: shouldAddBorder ? '' : 'hidden',
  };
};

export const SectionWhyAreYouTargeted = () => {
  const { entities_summary } = useResultsState();
  const [targetingReasons, setTargetingReasons] = useState([]);
  const callback = useCallback(async () => {
    const fetchTargetingData = async () => {
      try {
        const res = await fetchUserTargetingSummary();
        setTargetingReasons(res);
      } catch (error) {
        // don't know if we really care tbh
      }
    };

    await Promise.all([fetchTargetingData()]);
  }, []);

  useEffect(() => {
    callback();
  }, [callback]);

  const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });

  if (
    !targetingReasons ||
    targetingReasons.length === 0 ||
    entities_summary.records.length === 0
  ) {
    return '';
  }

  return (
    <Section>
      <SectionTitle>{strings.why_are_you_being_targeted.title}</SectionTitle>
      <SectionBody>
        <SectionContent>
          <Table style={{ textAlign: 'left' }}>
            <thead>
              <tr>
                <th>{strings.ads_table.page}</th>
                <th>{strings.ads_table.party}</th>
                <th>{strings.ads_table.targeting}</th>
              </tr>
            </thead>
            <tbody>
              {targetingReasons.map((reason, index) => {
                const shouldAddBorder = index !== targetingReasons.length - 1;
                const [entity] = filterEntitiesByEntityId(
                  entities_summary.records,
                  reason.entity_id
                );
                return (
                  <tr key={entity?.short_name + index}>
                    <td style={getTableRowBorderStyle(shouldAddBorder)}>
                      <span
                        className="font-weight-bold"
                        style={{
                          borderBottom: `2px solid ${entity?.color}`,
                        }}
                      >
                        <a className="noLink" href="#partySummary">
                          {reason.party}
                        </a>
                      </span>
                    </td>
                    <td style={getTableRowBorderStyle(shouldAddBorder)}>
                      {entity?.short_name || ''}
                    </td>
                    <td style={getTableRowBorderStyle(shouldAddBorder)}>
                      {listFormatter.format(reason.interests)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </SectionContent>
      </SectionBody>
    </Section>
  );
};

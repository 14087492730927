import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { isUpdatingProfile, strings } from '../../shared';

export const PageRegistrationContainer = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Row>
        <Col xs={12} md={10} className="my-4">
          <h2 className="pb-2 font-weight-bold">
            {isUpdatingProfile()
              ? pathname === '/language'
                ? strings.update.update_language
                : pathname === '/about-me'
                ? strings.update.update_profile
                : null
              : pathname.startsWith('/research-studies')
              ? strings.campaigns.research_studies
              : pathname === '/registration/language'
              ? strings.register.step_1
              : pathname === '/registration/consent'
              ? strings.register.step_2
              : strings.register.step_3}
          </h2>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

import React from 'react';
import { Row } from 'react-bootstrap';
import { strings } from '../../shared';

import { Footer } from '../PageResults/partials/Footer.js';

export const PageDeleteDataSuccess = () => {
  return (
    <div className="pageResults">
      <Row>
        <div>
          <div className="emptyView">
            <h5 className="subMessage bold">{strings.delete.data_deleted}</h5>
            <h6 className="subMessage">
              {strings.delete.data_deleted2}
              <div style={{ height: 20 }}></div>
              {strings.delete.data_deleted3}
            </h6>
          </div>
        </div>
      </Row>

      {/* Black footer with links and controls */}
      <Footer view={'data_deleted'} />
    </div>
  );
};

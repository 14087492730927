import React, { useEffect, useState } from 'react';
import { app } from '../../../shared';
import {
  CartesianGrid,
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

export const ChartPoliticalImpressionsByCountry = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await app.service('impressions-daily-totals').find({
          query: {
            country: props.country,
            $limit: 90,
          },
        });

        // parse integers and reverse data, before passing to recharts
        const data = response.data
          .map((item) => ({
            ...item,
            political_impressions: parseInt(item.political_impressions, 10),
            impressions: parseInt(item.impressions, 10),
            political_impressions_percentage:
              (parseInt(item.political_impressions, 10) /
                parseInt(item.impressions, 10)) *
              100,
          }))
          .reverse();

        setData(data);
      } catch (error) {
        // console.log(error);
      }
    };
    loadData();
  }, [props.country]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <Area
          dataKey="political_impressions_percentage"
          fill="#007bff"
          stroke="#facc2b"
          strokeWidth={2}
          type="monotone"
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis unit="%" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export const resultsReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_ENTITIES_SUMMARY':
      return {
        ...state,
        entities_summary: {
          ...state.entities_summary,
          is_empty: false,
          is_loading: false,
          records: action.payload,
        },
      };
    case 'LOADING_ENTITIES_SUMMARY':
      return {
        ...state,
        entities_summary: {
          ...state.entities_summary,
          is_loading: true,
        },
      };
    case 'LOADED_ENTITIES_SUMMARY':
      return {
        ...state,
        entities_summary: {
          ...state.entities_summary,
          is_loading: false,
        },
      };
    case 'FETCH_COUNTRIES':
      return {
        ...state,
        countries: {
          ...state.countries,
          is_empty: false,
          is_loading: false,
          records: action.payload,
        },
      };
    case 'LOADING_COUNTRIES':
      return {
        ...state,
        countries: {
          ...state.countries,
          is_loading: true,
        },
      };
    case 'LOADED_COUNTRIES':
      return {
        ...state,
        countries: {
          ...state.countries,
          is_loading: false,
        },
      };
    case 'FETCH_GEO':
      return {
        ...state,
        geo: {
          ...state.geo,
          is_empty: false,
          is_loading: false,
          records: action.payload,
        },
      };
    case 'LOADING_GEO':
      return {
        ...state,
        geo: {
          ...state.geo,
          is_loading: true,
        },
      };
    case 'LOADED_GEO':
      return {
        ...state,
        geo: {
          ...state.geo,
          is_loading: false,
        },
      };
    case 'FETCH_ADVERTS':
      return {
        ...state,
        adverts: {
          ...state.adverts,
          is_empty: false,
          is_loading: false,
          records: action.payload,
        },
      };
    case 'LOADING_ADVERTS':
      return {
        ...state,
        adverts: {
          ...state.adverts,
          is_loading: true,
        },
      };
    case 'LOADED_ADVERTS':
      return {
        ...state,
        adverts: {
          ...state.adverts,
          is_loading: false,
        },
      };
    default:
      throw new Error(
        `Action type ${action.type} is not supported in resultsReducer!`
      );
  }
};

import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { fetchCountries } from '../../api';
import { fetchGeo } from '../../api/fetchGeo.js';
import {
  useResultsDispatch,
  useResultsState,
} from '../../context/ResultsContext';
import {
  buildCountriesMap,
  getSharingMessage,
  getUserCountry,
  strings,
} from '../../shared';
import { ShareTextViaEmailButton } from './SharingButtons.js';

export const SharingBlock = ({ userCountryFromRegistration }) => {
  const { countries, geo } = useResultsState();
  const dispatch = useResultsDispatch();
  const userCountry = getUserCountry() || userCountryFromRegistration;
  const countriesMap = buildCountriesMap(countries.records);

  const userCountryNative = countriesMap[userCountry];
  const constituencyName = geo.records[0]?.name || null;
  const userCount = geo.records[0]?.users || 0;

  useEffect(() => {
    const fetch = async () => {
      if (
        !countries.is_loading &&
        countries.is_empty &&
        countries.records.length === 0
      ) {
        const countries = await fetchCountries({ query: { $limit: -1 } });
        dispatch({ type: 'FETCH_COUNTRIES', payload: countries });
      }

      if (!geo.is_loading && geo.is_empty) {
        const geo = await fetchGeo();
        dispatch({ type: 'FETCH_GEO', payload: geo });
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo, countries]);

  if (geo.is_empty || countries.records.length === 0) {
    return null;
  }

  const message = getSharingMessage(
    userCount,
    constituencyName || userCountryNative
  );

  const sharingMessage = (
    <>
      {message}
      <br />
      {strings.share.share4}
    </>
  );

  const shareLinkFB = () => {
    return (
      'https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwhotargets.me&quote=' +
      encodeURIComponent(strings.share.share_facebook)
    );
  };

  const shareLinkTwitter = () => {
    return (
      'https://twitter.com/intent/tweet?text=' +
      encodeURIComponent(strings.share.share_twitter)
    );
  };

  return (
    <>
      <Row className="mb-4">
        {!['GB', 'US'].includes(userCountry) || !constituencyName ? (
          <div className="sharingContainer">
            {geo.records.length !== 0 && (
              <span className="sharingMessage">{message}</span>
            )}
            <span className="sharingSubMsg font-weight-bold">
              {strings.share.share4}
            </span>
          </div>
        ) : (
          <div className="sharingContainer">
            <div className="sharingSubMsg">
              {constituencyName ? (
                <span>{sharingMessage}</span>
              ) : (
                <>
                  <span>{message}</span>
                  <br />
                  <span className="sharingSubMsg font-weight-bold">
                    {strings.share.share4}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </Row>

      <Row>
        <Button
          className="buttonFB mainLayout"
          variant="outline-primary"
          target="_blank"
          rel="noopener noreferrer"
          href={shareLinkFB()}
        >
          {strings.share.share_on_facebook}
        </Button>

        <Button
          className="buttonTW mainLayout"
          variant="outline-primary"
          target="_blank"
          rel="noopener noreferrer"
          href={shareLinkTwitter()}
        >
          {strings.share.share_on_twitter}
        </Button>

        <ShareTextViaEmailButton />
      </Row>
    </>
  );
};

import React from 'react';
import {
  CartesianGrid,
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { scrollToElement } from '../../../shared';

/**
 * interface data {
 *   advertiserName: String
 *   value: Integer
 *   color: String
 * }
 */

export const ChartPartyAdFrequency = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="p-3">
        <p>No data to display chart</p>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="5 5" />
        <Bar dataKey="percentage">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
        <XAxis
          dataKey="party"
          style={{ cursor: 'pointer' }}
          onClick={() => scrollToElement('partySummary')}
        />
        <YAxis
          unit="%"
          scale="linear"
          type="number"
          domain={[0, 100]}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { fetchCountries } from '../../../api/fetchCountries.js';
import { getUserCountry, strings } from '../../../shared';
import { ChartPoliticalImpressionsByCountry } from '../charts/ChartPoliticalImpressionsByCountry';
import {
  Section,
  SectionBody,
  SectionContent,
  SectionFooter,
  SectionTitle,
} from './Section';

export const SectionCountryBreakDowns = () => {
  const country = getUserCountry();
  const [activatedCountry, setActivatedCountry] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!activatedCountry) {
        const userCountry = await fetchCountries({
          query: {
            alpha2: country.toLowerCase(),
            $select: ['activated_at'],
            $limit: 1,
          },
        });

        if (userCountry.data[0].activated_at) {
          setActivatedCountry(true);
        }
      }
    };

    fetch();
  }, [activatedCountry, country]);

  if (!activatedCountry) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>
        {strings.chart_political_impressions_by_country.title}
      </SectionTitle>
      <SectionBody>
        <SectionContent>
          <div style={{ width: '100%', height: '400px' }}>
            <ChartPoliticalImpressionsByCountry country={country} />
          </div>
        </SectionContent>
        <SectionFooter>
          <div className="pb-3">
            {strings.chart_political_impressions_by_country.keyline}{' '}
            {strings.chart_political_impressions_by_country.fluffer}
          </div>
        </SectionFooter>
      </SectionBody>
    </Section>
  );
};

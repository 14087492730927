import { format, subDays } from 'date-fns';
import { app } from '../helpers/dataApiClient';

export const handleUserCredentials = async () => {
  const wtmdata = JSON.parse(localStorage.getItem('wtmdata')) || {};
  const general_token = JSON.parse(localStorage.getItem('general_token')) || '';
  const language = localStorage.getItem('language') || 'en';
  const { lastUpdated, userData } = wtmdata;

  if (
    shouldUpdateUserData(lastUpdated) ||
    Object.entries(wtmdata).length === 0
  ) {
    const data = await app.service('user-credentials').get(general_token);

    const { token, ...rest } = data;

    localStorage.setItem(
      'wtmdata',
      JSON.stringify({
        userData: rest,
        lastUpdated: format(new Date(), 'yyyy-MM-dd'),
      })
    );

    return { userData: data, language, general_token };
  }

  return { userData, language, general_token };
};

const shouldUpdateUserData = (lastUpdated) => {
  if (!lastUpdated) return true;
  return new Date(lastUpdated) < subDays(new Date(), 1);
};

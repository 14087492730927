import React from 'react';
import { FACEBOOK_AGE_OFFSET, capitalize, strings } from '../../shared';

export const RationaleView = ({ ad }) => {
  /*
  Example display:
  Age range: 18-65
  Location: The United States
  Gender: Male
  Custom Audience: Yes
  Lookalike Audience: Yes
  Interest(s): Talladega Superspeedway
  */
  const {
    waistAgeMax,
    waistAgeMin,
    waistArea,
    waistCustomAudience,
    waistGender,
    waistInterests,
    waistCustomAudiencePage,
    waistLookalikeAudience,
  } = ad;

  const ageRange =
    waistAgeMin && waistAgeMax
      ? `${waistAgeMin + FACEBOOK_AGE_OFFSET}-${
          waistAgeMax + FACEBOOK_AGE_OFFSET
        }`
      : null;

  const customAudience = waistCustomAudiencePage
    ? waistCustomAudiencePage
    : waistCustomAudience
    ? 'Yes'
    : 'No';
  const lookAlikeAudience = waistLookalikeAudience ? 'Yes' : 'No';

  const dataAvailable = [
    ageRange,
    waistArea,
    customAudience,
    waistGender,
    waistInterests,
    lookAlikeAudience,
  ]
    .map((d) => d)
    .filter((d) => d).length;

  return (
    <div>
      {dataAvailable ? (
        <ul className="rationaleLst">
          {ageRange && (
            <li>{`${strings.ads_table.rationale_age}: ${ageRange}`}</li>
          )}
          {waistArea && (
            <li>{`${strings.ads_table.rationale_location}: ${capitalize(
              waistArea
            )}`}</li>
          )}
          {waistGender && (
            <li>{`${strings.ads_table.rationale_gender}: ${waistGender}`}</li>
          )}
          {customAudience && (
            <li>
              {`${strings.ads_table.rationale_custom_audience}:`}{' '}
              {typeof customAudience === 'string' ? (
                customAudience
              ) : (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={customAudience.ca_owner_page_url}
                >
                  {customAudience.ca_owner_name}
                </a>
              )}
            </li>
          )}
          {lookAlikeAudience && (
            <li>{`${strings.ads_table.rationale_lookalike_audience}: ${lookAlikeAudience}`}</li>
          )}
          {waistInterests && (
            <li>{`${
              strings.ads_table.rationale_interests
            }: ${waistInterests.map((i) => i.name).join(', ')}`}</li>
          )}
        </ul>
      ) : (
        <div>{strings.utils.not_available}</div>
      )}
    </div>
  );
};

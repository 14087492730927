import { patchUserAccount } from '../../api';
import { findSafari } from '../../shared';

export const standardUserObj = {
  country: 'US',
  age: 20,
  gender: 1,
  postcode: 4,
  politicalAffiliation: 7, // By default, no political affiliation is chosen
  survey: null,
  email: null,
  consent: '',
};

export const handleUserForms = async (userObj, isUpdatingUser) => {
  const safariBrowser = findSafari();

  try {
    // Extension should handle the user creation if
    // we are not updating the user, i.e. creating user for the first time
    if (!isUpdatingUser && !safariBrowser) {
      window.postMessage({
        ...userObj,
        political_affiliation: userObj.politicalAffiliation,
        registerWTMUser: true,
      });
      return { success: true };
    }

    const token = JSON.parse(localStorage.getItem('general_token'));
    const response = await patchUserAccount(token, userObj);

    if (!response.token) {
      console.error(response);
      return { success: false };
    }
    if (!isUpdatingUser) {
      let general_token = response.token;
      localStorage.setItem('general_token', JSON.stringify(general_token));
      if (safariBrowser) {
        window.postMessage({ safariGeneralToken: general_token }, '*');
      }
    }
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

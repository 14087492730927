/**
 * State machine uses https://xstate.js.org/docs/
 * For visual, paste this file in to https://stately.ai/viz
 */

import { createMachine, assign } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { browserHistory } from '../Shell/browserHistory';
import { researchStudiesMap } from '../../shared';

export const registrationModel = createModel(
  {},
  {
    events: {
      NEXT: (values, context) => ({ values, context }),
      PREVIOUS: () => ({}),
    },
  }
);

export const registrationMachine = createMachine(
  {
    id: 'registration',
    initial: 'language',
    context: {
      payload: {},
    },
    states: {
      language: {
        entry: 'navigate',
        on: {
          NEXT: {
            target: 'consent',
          },
        },
        meta: {
          route: '/registration/language',
        },
      },
      consent: {
        entry: 'navigate',
        exit: 'savePayload',
        on: {
          NEXT: {
            target: 'aboutMe',
          },
          PREVIOUS: {
            target: 'language',
          },
        },
        meta: {
          route: '/registration/consent',
        },
      },
      aboutMe: {
        entry: 'navigate',
        exit: 'savePayload',
        on: {
          NEXT: [
            {
              cond: 'shouldAccessResearch',
              target: 'researchStudies',
            },
            {
              target: 'share',
            },
          ],
          PREVIOUS: {
            target: 'consent',
          },
        },
        meta: {
          route: '/registration/about-me',
        },
      },
      researchStudies: {
        entry: 'navigate',
        exit: 'savePayload',
        on: {
          NEXT: {
            target: 'share',
          },
          PREVIOUS: {
            target: 'aboutMe',
          },
        },
        meta: {
          route: '/registration/research-studies',
        },
      },
      share: {
        entry: 'navigate',
        exit: 'savePayload',
        on: {
          NEXT: {
            target: 'results',
          },
          PREVIOUS: {
            target: 'aboutMe',
          },
        },
        meta: {
          route: '/registration/share',
        },
      },
      results: {
        type: 'final',
        entry: 'navigate',
        meta: {
          route: '/',
        },
      },
    },
  },
  {
    guards: {
      shouldAccessResearch: (_context, event) =>
        event.type === 'NEXT' &&
        (researchStudiesMap[event.values.country] || []).length !== 0,
    },
    actions: {
      navigate: (_context, event, meta) => {
        const stateKey = `${meta.state.machine.key}.${meta.state.value}`;
        const metaRoute = meta.state.meta[stateKey].route;

        if (metaRoute.includes('research-studies')) {
          browserHistory.push(metaRoute + `/${event.values.country}`, true);
        } else browserHistory.push(metaRoute);
      },
      savePayload: assign((context, event) => {
        return {
          payload: {
            ...context.payload,
            ...event.values,
          },
        };
      }),
    },
  }
);

export const persistedRegistrationState =
  JSON.parse(localStorage.getItem('registration_machine_state')) ||
  registrationMachine.initialState;

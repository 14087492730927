import React, { useState, useEffect } from 'react';
import { ChartPartyAdFrequency } from '../charts/ChartPartyAdFrequency';
import {
  Section,
  SectionBody,
  SectionContent,
  SectionFooter,
  SectionLead,
  SectionTitle,
} from './Section';
import { Select } from '../partials/Select';
import { sortBy } from 'lodash';
import { useResultsState } from '../../../context/ResultsContext';
import { buildCountriesMap, LoadingPlaceholder } from '../../../shared';
import { getUserCountry, strings } from '../../../shared';
import { fetchCountryStats, fetchGeo } from '../../../api';
import { format, subDays } from 'date-fns';
import { placeholders } from '../../../shared/helpers/placeholder';

const mergeCountryStatsWithEntitiesSummary = (
  countryStats,
  entitiesSummary
) => {
  return entitiesSummary.map((entity) => {
    const [countryStatRelatedToEntity] = countryStats.filter(
      (stat) => stat.party.toLowerCase() === entity.short_name.toLowerCase()
    );

    if (countryStatRelatedToEntity) {
      return {
        ...countryStatRelatedToEntity,
        color: entity?.color || '',
      };
    } else {
      return {
        count: '0',
        percentage: '0',
        party: entity?.short_name,
      };
    }
  });
};

export function SectionNearYou() {
  const { entities_summary, countries } = useResultsState();

  const [constituency, setConstituency] = useState('');
  const [countryStats, setCountryStats] = useState([]);
  const [geosStat, setGeosStat] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const userCountry = getUserCountry();
  const countriesMap = buildCountriesMap(countries.records);

  const hasData =
    userCountry &&
    geosStat.length !== 0 &&
    constituency?.length !== 0 &&
    countries.records.length !== 0;

  useEffect(() => {
    const fetch = async () => {
      if (constituency?.length === 0) {
        setLoading(true);
        const geo = await fetchGeo();
        setConstituency(geo[0]?.name?.replace(',', ''));
      }

      if (countryStats.length === 0) {
        setLoading(true);
        const createdAt = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        const stats = await fetchCountryStats({
          query: { country: userCountry, createdAt: { $gt: createdAt } },
        });
        setCountryStats(stats.data[0]?.statistics?.geo || '');
      }
    };

    fetch().finally(() => setLoading(false));
  }, [countryStats, userCountry, constituency]);

  useEffect(() => {
    const shouldUpdateGeosStat =
      constituency &&
      countryStats.length !== 0 &&
      entities_summary.records.length !== 0;

    if (shouldUpdateGeosStat) {
      // default to the first constituency if no stat is found for user's constituency
      const defaultConstituency = countryStats[Object.keys(countryStats)[0]];

      setGeosStat(
        mergeCountryStatsWithEntitiesSummary(
          countryStats[constituency] || defaultConstituency,
          entities_summary.records
        )
      );
    }

    if (
      typeof constituency === 'undefined' ||
      (constituency === null && Object.keys(countryStats).length !== 0)
    ) {
      setConstituency(Object.keys(countryStats)[0]);
    }

    return () => {};
  }, [entities_summary.records, constituency, countryStats]);

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (!hasData) {
    return (
      <div>
        <h5 className="subMessage">
          {constituency &&
            placeholders(strings.results.no_geo_explanation, {
              constituency,
            })}
        </h5>
      </div>
    );
  }

  const options = sortBy(
    Object.keys(countryStats).map((key) => ({
      value: key,
      label: key,
    })),
    'value'
  );

  return (
    <Section>
      <SectionTitle>{strings.filters.geo}</SectionTitle>
      <SectionBody>
        <SectionLead>
          <div className="pt-1" style={{ fontSize: '1.1rem' }}>
            {userCountry === 'GB'
              ? 'Constituency of '
              : userCountry === 'US'
              ? 'Pick a state: '
              : ''}
          </div>
          <Select
            options={options}
            selected={constituency}
            selectedDefault={constituency}
            sendState={(value) => {
              setConstituency(value.selected);
              setGeosStat([]);
            }}
          />
        </SectionLead>
        <SectionContent>
          <div style={{ width: '100%', height: '400px' }}>
            <ChartPartyAdFrequency data={geosStat} />
          </div>
        </SectionContent>
        <SectionFooter>
          <div className="pb-3">
            {`${strings.results.how_did_we_calc1} ${countriesMap[userCountry]}  | `}
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://whotargets.me/en/defining-political-ads/"
            >
              {strings.results.how_did_we_calc2}
            </a>
          </div>
        </SectionFooter>
      </SectionBody>
    </Section>
  );
}

class Storage {
  constructor(keys, state) {
    this.loadState = (keys) => {
      return new Promise((resolve, reject) => {
        const result = {};
        for (let key of keys) {
          try {
            const serState = localStorage.getItem(key);
            if (serState) {
              result[key] = JSON.parse(serState);
            }
          } catch (err) {
            console.log(err);
          }
        }
        // if anything there, resolve/
        if (Object.keys(result).length) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    };

    this.saveState = (key, state) => {
      // console.log('saveState', key, state)
      return new Promise((resolve, reject) => {
        try {
          const serState = JSON.stringify(state);
          localStorage.setItem(key, serState);
          resolve(key);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    };

    this.deleteState = () => {
      localStorage.clear();
    };
  }
}

export const storage = new Storage('wtm', null);

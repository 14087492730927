import { paramsForServer } from 'feathers-hooks-common';
import { app } from '../shared';

export const fetchEntitiesSummary = async (userCountry) => {
  return app.service('entities-summary').find(
    paramsForServer({
      query: {
        countries: {
          alpha2: { $in: [userCountry.toLowerCase()] },
        },
        is_political: true,
        is_active: true,
      },
      paginate: false,
      withCountries: true,
    })
  );
};

import React, { useEffect, useState } from 'react';
import '../../../../typedef';
import { BlockHeader } from '../blocks';
import { isEmpty } from 'lodash';
import { subMonths, format } from 'date-fns';
import {
  SharingBlock,
  strings,
  getUserCountry,
  userHasPoliticalData,
  getPercentageOfTotal,
  getPercentageRemainOfTotal,
  LoadingPlaceholder,
} from '../../../../shared';
import { useResultsState } from '../../../../context/ResultsContext';
import {
  fetchUserPartyTargeting,
  fetchUserPartyTargetingSummary,
  fetchUserPartyPeriodPostImpressions,
  fetchCountryStats,
} from '../../../../api';
import {
  buildCountriesMap,
  filterEntitiesByEntityId,
  isCountrySupported,
} from '../../../../shared/';
import { placeholders } from '../../../../shared/helpers/placeholder';
import { LargeOrganizationalEntityImage } from '../../../../shared/components/LargeOrganizationalEntityImage';

/**
 * @returns {React.Component}
 */
export const StatsBlock = () => {
  const { entities_summary, countries, adverts } = useResultsState();
  const countriesMap = buildCountriesMap(countries.records);
  const userCountry = getUserCountry();

  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState(false);

  const [userPartyTargetingSummary, setUserPartyTargetingSummary] = useState(
    {}
  );
  const [userPartyTargeting, setUserPartyTargeting] = useState([]);
  const [userSeenSummaries, setUserSeenSummaries] = useState({});
  const [countryStats, setCountryStats] = useState({});
  const countrySupported = isCountrySupported(userCountry, countries.records);
  const [entity] = filterEntitiesByEntityId(
    entities_summary.records,
    userPartyTargeting[0]?.entity_id
  );

  async function fetchUserTargetedByFrequency() {
    try {
      /** @type {UserPartyTargetingSummary} */
      const response = await fetchUserPartyTargetingSummary();
      setUserPartyTargetingSummary(response);
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async function fetchUserPartyTargetingTotals() {
    /** @type {UserPartyPostimpressions[]} - sorted by postimpression_count in api */
    const response = await fetchUserPartyTargeting();
    setUserPartyTargeting(response);
  }

  async function fetchUserSeenSummaries() {
    const response = await fetchUserPartyPeriodPostImpressions();
    setUserSeenSummaries(response);
  }

  async function conuntryStats(country) {
    const response = await fetchCountryStats({ query: { country } });
    setCountryStats(response?.data[0]?.statistics.local_stats);
  }

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchUserTargetedByFrequency(),
      fetchUserPartyTargetingTotals(),
      fetchUserSeenSummaries(),
      conuntryStats(userCountry),
    ])
      .then(() => setIsLoading(false))
      .catch((e) => {
        setIsLoading(false);
        setIsError(true);
        console.log(e);
      });
  }, [userCountry]);

  /** @type {Number} total ads seen by user */
  const userSeenPartiesSum = userPartyTargeting.reduce(
    (acc, curr) => (acc += parseInt(curr.postimpression_count, 10)),
    0
  );

  // most ads seen percentage
  const userMostSeenAsPercentageOfAllSeen = getPercentageOfTotal(
    userPartyTargeting[0]?.postimpression_count,
    userSeenPartiesSum
  );

  function compareNumbers(num1, num2, proportionLabel, directionLabel) {
    let proportion = 0;
    let direction = '';
    if (!num1 && num2) {
      proportion = -100;
    } else if (num1 && !num2) {
      proportion = 100;
    } else if (num1 && num2) {
      const _proportion = num1 / num2;
      if (_proportion > 1) {
        proportion = parseInt((_proportion - 1) * 100, 10);
        direction = strings.utils.more;
      } else {
        proportion = parseInt((1 - _proportion) * 100, 10);
        direction = strings.utils.fewer;
      }
    }

    // If all ads are from this week, then we should say 100% more from this week
    if (proportion === 100) {
      direction = strings.utils.more;
    }

    return { [proportionLabel]: proportion, [directionLabel]: direction };
  }

  const {
    activeUsers,
    totalUsersWhoSeenPolitAds,
    avgPolitAdsSeen,
  } = countryStats;

  // 90 days back from current date or user register date whichever latest
  const dateThreeMonthsAgo = format(subMonths(new Date(), 3), 'do LLLL yyy');

  // 27% of WTM users in US are yet to see any polit ad ad at all...
  const seenPolitAdPerc = getPercentageRemainOfTotal(
    totalUsersWhoSeenPolitAds,
    activeUsers
  );

  // you've seen 15% more polit ads in the last week than in the week before
  const { proportionSeen, directionSeen } = compareNumbers(
    userSeenSummaries.seenAdsInLastDays7,
    userSeenSummaries.seenAdsInLastDays14 -
      userSeenSummaries.seenAdsInLastDays7,
    'proportionSeen',
    'directionSeen'
  );

  // you're seeing 25% more ads than average user in US
  const { directionVsCountry } = compareNumbers(
    userSeenSummaries.seenAdsInLastDays90,
    avgPolitAdsSeen,
    'proportionVsCountry',
    'directionVsCountry'
  );

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (isError || !entity) {
    return <></>;
  }

  return (
    <div className="mb-5">
      <BlockHeader
        props={{
          blockHeader: entity ? strings.stats.stats_block_header_personal : '',
        }}
        style={{ textAlign: 'center' }}
      />

      <div
        className="pr-5 pl-5 pt-5 pb-4"
        style={{
          border: `6px solid ${entity?.color ?? '#ccc'}`,
          borderRadius: '5px',
          backgroundColor: '#F2F2F2',
        }}
      >
        <div
          style={{
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {countrySupported && userHasPoliticalData(adverts.records) && (
            <>
              <div className="mt-2">
                <LargeOrganizationalEntityImage
                  id={entity?.id}
                  borderColor={entity?.color}
                  shortName={entity?.short_name}
                />
              </div>

              <div>
                <ul>
                  <li style={{ listStyle: 'disc' }}>
                    {placeholders(strings.stats.stats_message, {
                      dateThreeMonthsAgo,
                      userSeenPartiesSum,
                      postimpressionsCount:
                        userPartyTargeting[0]?.postimpression_count,
                      userMostSeenAsPercentageOfAllSeen,
                    })}
                    <> </>
                    {/* {`${sprintf(
                      strings.stats.stats_message,
                      dateThreeMonthsAgo,
                      userSeenPartiesSum,
                      userPartyTargeting[0]?.postimpression_count,
                      userMostSeenAsPercentageOfAllSeen
                    )} `} */}
                    <span
                      className="party"
                      style={{
                        color: entity ? entity.color : '#ccc',
                        fontWeight: 'bold',
                      }}
                    >
                      {entity?.name?.toUpperCase()}
                    </span>
                  </li>

                  {!isEmpty(userPartyTargetingSummary) && (
                    <li style={{ listStyle: 'disc' }}>
                      {placeholders(
                        strings.stats.stats_message_targeting_frequency,
                        {
                          unique_candidate_count:
                            userPartyTargetingSummary.unique_candidate_count,
                          unique_party_count:
                            userPartyTargetingSummary.unique_party_count,
                        }
                      )}
                    </li>
                  )}

                  {proportionSeen !== 0 && (
                    <li style={{ listStyle: 'disc' }}>
                      {placeholders(strings.stats.seen_last_week, {
                        proportionSeen,
                        directionSeen,
                      })}
                    </li>
                  )}

                  <li style={{ listStyle: 'disc' }}>
                    {placeholders(strings.stats.seen_last_week_country, {
                      directionVsCountry,
                    })}
                    {countriesMap[userCountry]}
                  </li>

                  <li style={{ listStyle: 'disc' }}>
                    {placeholders(strings.stats.seen_polit_ads, {
                      seenPolitAdPerc,
                    })}
                  </li>

                  <li style={{ listStyle: 'disc' }}>
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://trends.whotargets.me/countries/${userCountry}/explorations/ad-spending`}
                    >
                      {strings.results.what_has_been_spent}
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>

        <hr
          style={{
            backgroundColor: '#ccc',
            borderColor: '#ccc',
            height: '2px',
            border: '0',
          }}
        />

        <div className="pt-3">
          <SharingBlock />
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { config } from '../../config';

export const OrganizationalEntityImage = ({ id, borderColor, shortName }) => {
  const [logoNotFound, setLogoNotFound] = useState(false);

  const imageProperties = {
    bucket: 'wtm-images-upload-production',
    key: `LOGO_${id}`,
    edits: {
      resize: {
        width: 100,
        height: 100,
        fit: 'cover',
      },
    },
  };

  if (!shortName) {
    return null;
  }

  if (logoNotFound) {
    return (
      <svg height="50" width="50">
        <circle cx="20" cy="20" r="40%" fill={borderColor} />
        <text
          x="60%"
          y="60%"
          textAnchor="middle"
          fill="white"
          style={{ fontSize: '0.6em' }}
          dy="-0.7em"
          dx="-1.1em"
        >
          {shortName.toUpperCase()}
        </text>
      </svg>
    );
  }

  return (
    <img
      className="heroImg"
      src={`${config.REACT_APP_CF_IMAGE_HOST}/${window.btoa(
        JSON.stringify(imageProperties)
      )}?v=0`}
      onError={() => setLogoNotFound(true)}
      style={{ borderColor: borderColor, borderWidth: 'medium' }}
      alt={shortName}
    />
  );
};

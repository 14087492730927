import { strings } from '..';
import { placeholders } from './placeholder';

export const getSharingMessage = (userCount, nativeCountry) => {
  userCount = parseInt(userCount);

  if (userCount < 50) {
    const newUserCount = 50;
    return placeholders(strings.share.share3_under, {
      newUserCount,
      nativeCountry,
    });
  }

  if (userCount >= 50) {
    const newUserCount = 50;
    return placeholders(strings.share.share3, { newUserCount, nativeCountry });
  }

  if (userCount >= 100) {
    const newUserCount = 100;
    return placeholders(strings.share.share3, { newUserCount, nativeCountry });
  }

  if (userCount >= 500) {
    const newUserCount = 500;
    return placeholders(strings.share.share3, { newUserCount, nativeCountry });
  }

  if (userCount >= 1500) {
    const newUserCount = 1500;
    return placeholders(strings.share.share3, { newUserCount, nativeCountry });
  }

  if (userCount >= 1999) {
    const newUserCount = 2000;
    return placeholders(strings.share.share3, { newUserCount, nativeCountry });
  }

  const newUserCount = parseInt(String(userCount)[0]) * 1000;
  return placeholders(strings.share.share3, { newUserCount, nativeCountry });
};

import React, { useEffect, useState } from 'react';
import { strings } from '../../shared';
import { Loading } from '../Loading';
import Chrome from '../Shell/partials/img/Chrome.png';
import Edge from '../Shell/partials/img/Edge.png';
import Firefox from '../Shell/partials/img/Firefox.png';
import Safari from '../Shell/partials/img/Safari.png';
import Logo from '../Shell/wtm_logo_2020.png';
import './styles.css';

const extensionButtons = [
  {
    name: 'Chrome',
    classes: 'shareBtns mr-2 btn btn-lg border border-primary',
    img: Chrome,
    imgAlt: 'Chrome logo',
    url:
      'https://chrome.google.com/webstore/detail/who-targets-me/fcejbjalmgocomoinikjejnkimlnoljp',
  },
  {
    name: 'Firefox',
    classes: 'shareBtns mr-2 px-4 btn btn-lg border border-primary',
    img: Firefox,
    imgAlt: 'Firefox logo',
    url:
      'https://addons.mozilla.org/en-US/firefox/addon/who-targets-me-firefox',
  },
  {
    name: 'Edge',
    classes: 'shareBtns mr-2 px-4 btn btn-lg border border-primary',
    img: Edge,
    imgAlt: 'Edge logo',
    url:
      'https://microsoftedge.microsoft.com/addons/detail/who-targets-me/fphblnblkkcngpagebnmllmacbeipinc',
  },
  {
    name: 'Safari',
    classes: 'shareBtns mr-2 btn btn-lg border border-primary',
    img: Safari,
    imgAlt: 'Safari logo',
    url: 'https://apps.apple.com/gb/app/who-targets-me/id1528704214',
  },
];

export const PageExtensionNotInstalled = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timeCounter = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeCounter);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="middle-outer">
      <div className="middle-inner" style={{ fontSize: '1.1rem' }}>
        <img src={Logo} alt="Who Targets Me?" />
        <div>{strings.helper_screens.no_extension4}</div>
        <div className="mt-4">
          {extensionButtons.map((button) => (
            <button
              key={button.name}
              className={button.classes}
              onClick={() =>
                window.open(button.url, '_blank', 'noopener,noreferrer')
              }
            >
              {button.name}
              <span className="ml-3">
                <img
                  src={button.img}
                  alt={button.imgAlt}
                  className="browserImg"
                />
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

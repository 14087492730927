import React, { createContext, useReducer, useContext } from 'react';
import { resultsReducer } from './resultsReducer';

const ResultsStateContext = createContext(undefined);
const ResultsDispatchContext = createContext(undefined);

const ResultsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(resultsReducer, {
    entities_summary: { is_loading: false, is_empty: true, records: [] },
    countries: { is_loading: false, is_empty: true, records: [] },
    geo: { is_loading: false, is_empty: true, records: [] },
    adverts: { is_loading: false, is_empty: true, records: [] },
  });

  return (
    <ResultsStateContext.Provider value={state}>
      <ResultsDispatchContext.Provider value={dispatch}>
        {children}
      </ResultsDispatchContext.Provider>
    </ResultsStateContext.Provider>
  );
};

const useResultsState = () => {
  const context = useContext(ResultsStateContext);
  if (context === undefined)
    throw new Error('useResultsState is not used within a ResultsProvider');
  return context;
};

const useResultsDispatch = () => {
  const context = useContext(ResultsDispatchContext);
  if (context === undefined)
    throw new Error('useResultsDispatch is not used within a ResultsProvider');
  return context;
};

export { ResultsProvider, useResultsDispatch, useResultsState };

import React from 'react';
import { strings } from '../../shared';
import Logo from '../Shell/wtm_logo_2020.png';

export const Loading = (props) => {
  const { errorLoading = false } = props;

  return (
    <div className="middle-outer">
      <div className="middle-inner">
        <img src={Logo} alt="Who Targets Me?" />
        {!errorLoading && <p>{strings.loading}</p>}
        {errorLoading && (
          <p style={{ color: 'red' }}>{strings.errors.loading_error}</p>
        )}
      </div>
    </div>
  );
};

import React from 'react';

export const OrderedListForm = ({ children, heading, description }) => {
  return (
    <li className="ml-n4 regMessage">
      <h5>{heading}</h5>
      <div className="resetFontSize" style={{ marginLeft: '-2.5%' }}>
        <p className="text-secondary" style={{ fontSize: '1rem' }}>
          {description}
        </p>
        {children}
      </div>
    </li>
  );
};

import { config } from '../../config';
import {
  USER_LOCAL_STORAGE_KEY,
  USER_AUTH_LOCAL_STORAGE_KEY,
  AVAILABLE_PLATFORMS,
} from '../constants';

/**
 * Gets user's country from local storage
 * @returns {String|null}
 */
export function getUserCountry() {
  let country = null;
  const wtmdata = window.localStorage.getItem('wtmdata');
  if (wtmdata) {
    const data = JSON.parse(wtmdata);
    country = data.userData.country;
  }
  return country;
}

export function userHasPoliticalData(adverts) {
  return adverts && adverts.length !== 0;
}

/* AS Per: 27/06/2024 example 'wtm_user' localStorage data:
 *
 *    // "wtm_user"
 *    {
 *        ... // other user data
 *        consent: {
 *            platforms: {
 *               facebook: true,
 *               twitter: false,
 *               instagram: true,
 *               youtube: false
 *            },
 *            agreedToTermsAndConditionsDate: "2024-06-27T09:45:35.395Z",
 *            appTermsAndConditionsDate: "2022-08-21T00:00:00.000Z",
 *            appVersion: "1.7.0"
 *        }
 *    }
 *
 */

let userLocaStorage = null;

export const getUser = () => {
  if (userLocaStorage === null) {
    userLocaStorage = new UserLocalStorage();
  }
  return userLocaStorage;
};

class UserLocalStorage {
  get loggedInToken() {
    return localStorage.getItem(USER_AUTH_LOCAL_STORAGE_KEY);
  }

  get user() {
    return JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY));
  }

  get isLoggedIn() {
    return !!this.loggedInToken;
  }

  get hasAgreedToLatestTermsAndConditions() {
    const { agreedToTermsAndConditionsDate } = this.user?.consent || {};

    if (!agreedToTermsAndConditionsDate) {
      return false;
    }

    try {
      const latestTermsAndConditionsDate = new Date(
        config.TERMS_AND_CONDITIONS_DATE
      );

      if (latestTermsAndConditionsDate && agreedToTermsAndConditionsDate) {
        const userConsentDate = new Date(agreedToTermsAndConditionsDate);

        if (latestTermsAndConditionsDate > userConsentDate) {
          return false;
        }
      }
    } catch (error) {
      console.error(
        'Error while checking hasAgreedToLatestTermsAndConditions',
        error
      );
    }

    return true; // If there is an error, don't block the user
  }

  get platforms() {
    return this.user?.consent?.platforms || {};
  }

  get hasConsentForAllPlatforms() {
    if (this.user === null) {
      return false;
    }

    const { platforms } = this.user.consent || {};

    if (!platforms) {
      return false;
    }

    for (const platform of AVAILABLE_PLATFORMS) {
      if (platforms[platform] === undefined) return false;
    }

    return true;
  }

  get shouldReconsent() {
    return (
      !this.hasConsentForAllPlatforms ||
      !this.hasAgreedToLatestTermsAndConditions
    );
  }

  updateConsent(platforms) {
    const updatedUser = {
      ...this.user,
      consent: {
        ...this.user?.consent,
        platforms: {
          ...this.user?.consent?.platforms,
          ...platforms,
        },
        appVersion: config.APP_VERSION,
        appTermsAndConditionsDate: config.TERMS_AND_CONDITIONS_DATE,
        agreedToTermsAndConditionsDate: new Date().toISOString(),
      },
    };

    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(updatedUser));
  }

  syncWithExtension() {
    window.postMessage(
      {
        action: 'UPDATE_USER',
        payload: this.user,
      },
      '*'
    );
  }
}
